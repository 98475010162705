import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import Body from "../../components/Body";
import Container from "../../components/Container";
import { Section } from "./styles";
import { colors } from "../../colors";

const resetableState = {};

export default class Main extends Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        voucher: PropTypes.object,
        businessInfo: PropTypes.object,
      }),
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func,
      push: PropTypes.func,
    }).isRequired,
  };

  state = {
    ...resetableState,
    businessInfo: {},
    voucher: {},
    loaded: false,
  };

  async componentDidMount() {
    const { location, history } = this.props;
    const { state } = location;
    if (state) {
      const { businessInfo, voucher, amount, name } = state;
      this.setState({ businessInfo, voucher, amount, name, loaded: true });
    } else {
      history.replace({ pathname: "/redeem" });
    }
  }

  render() {
    const { businessInfo, amount, name } = this.state;

    return (
      <Body primaryColor={businessInfo.color}>
        <Container>
          <Section>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "3%",
              }}
            >
              <img
                src={require("../../assets/tick.png")}
                style={{
                  width: "20%",
                }}
                alt='Sucesso!'
              />
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontFamily: "Poppins",
                marginTop: "3%",
              }}
            >
              <h2>Resgate efetuado com sucesso!</h2>
              <h3 style={{ marginTop: "3%" }}>
                {name} você resgatou {amount} em{" "}
                {businessInfo.fancyName
                  ? businessInfo.fancyName
                  : businessInfo.name
                  ? businessInfo.name
                  : businessInfo.partnerName}
                .
              </h3>
            </div>
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button
                style={{
                  backgroundColor: colors.lightPurple,
                  border: "none",
                  color: "#fff",
                  fontWeight: "bold",
                  fontFamily: "Poppins, sans-serif",
                  width: "30%",
                  height: "4.5vw",
                  fontSize: "1.0vw",
                  marginTop: "4%",
                  marginBottom: "4%",
                }}
                onClick={() => {
                  this.props.history.push({
                    pathname: "/redeem",
                    state: { businessInfo },
                  });
                }}
              >
                voltar ao ínicio
              </Button>
            </div>
          </Section>
        </Container>
      </Body>
    );
  }
}
