import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
    prefix: "R$",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false,
    integerLimit: 5,
};

const CurrencyInput = ({ maskOptions, field, ...props }) => {
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    });

    return (
        <MaskedInput
            mask={currencyMask}
            inputMode="decimal"
            {...field}
            {...props}
        />
    );
};

CurrencyInput.defaultProps = {
    inputMode: "numeric",
    maskOptions: {},
    field: {},
};

CurrencyInput.propTypes = {
    inputMode: PropTypes.string,
    maskOptions: PropTypes.shape({
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeparator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.string,
        requireDecimal: PropTypes.bool,
        integerLimit: PropTypes.number,
        allowNegative: PropTypes.bool,
        allowLeadingZeroes: PropTypes.bool,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    field: PropTypes.object,
};

export default CurrencyInput;
