import currency from "./currency";

export const makeMinCurrency = (min) => {
  return (value) => {
    if (!value) {
      return true;
    }

    return currency.toCents(value) >= min;
  };
};

export const makeMaxCurrency = (max) => {
  return (value) => {
    if (!value) {
      return true;
    }

    return currency.toCents(value) <= max;
  };
};
