import React, { useEffect, useState } from "react";
import { Modal, List, Card, Spin } from "antd";
import api from "../../services/api";
import { LoadingOutlined } from "@ant-design/icons";
import { styles } from "./styles";
import { IoMdBusiness } from "react-icons/io";
import { colors } from "../../colors";
import { Tabs } from './styles';

interface campaign {
    [key: string]: any;
}

const CampaignsModal = (props) => {
    const [campaigns, setCampaigns] = useState<Array<campaign>>([]);
    const [campaignsFiltered, setCampaignsFiltered] = useState<Array<campaign>>([]);
    const [loading, setLoading] = useState(true);
    const [campaignTypeSelected, setCampaignTypeSelected] = useState('all');
    const [campaignTypes, setCampaignTabs] = useState<Array<campaign>>([]);

    const antIcon = (
        <LoadingOutlined
            style={{ fontSize: 24, color: colors.purpleColor }}
            spin
        />
    );

    useEffect(() => {
        async function getCampaignTypes() {
            try {
                const types = await api.get('v2/customer/campaign-types');
                setCampaignTabs(types.data.data);
            } catch {
                //
            }
        }

        getCampaignTypes();
    }, []);

    useEffect(() => {
        api.get(`/v2/customer/establishments/${props.establishmentId}/campaigns`)
            .then((data) => {
                setCampaigns(data.data.data);
                setCampaignsFiltered(data.data.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [props.establishmentId]);

    useEffect(() => {
        if (campaignTypeSelected === 'all') {
            setCampaignsFiltered(campaigns);
        } else {
            setCampaignsFiltered(campaigns.filter(x => x.campaignTypeId === campaignTypes.find(x => x.name === campaignTypeSelected)?.id));
        }
    }, [campaignTypeSelected, campaigns]);

    return (
        <Modal
            title=""
            visible={props.showModal}
            onOk={() => props.setModal()}
            onCancel={() => props.setModal()}
            cancelButtonProps={{ disabled: true }}
            footer={[null]}
            width={800}
            style={{ borderRadius: 20, padding: 20 }}
        >
            <div>
                {props.establishment.logo ? (
                    <img
                        src={props.establishment.logo.location}
                        alt="logo"
                        style={styles.logo}
                    />
                ) : (
                    <IoMdBusiness color="#cccccc" />
                )}
                <p style={styles.campaignTitle}>Campanhas disponíveis</p>
            </div>

            <p style={styles.campaignSubtitle}>
                {props.establishment.fancyName
                    ? props.establishment.fancyName
                    : props.establishment.name
                        ? props.establishment.name
                        : props.establishment.partnerName}
            </p>


            <Tabs
                tabs={[
                    { description: 'Tudo', value: 'all'},
                    { description: 'Produtos', value: 'product'},
                    { description: 'Ingressos', value: 'ticket'},
                    { description: 'Créditos', value: 'credit'}]}
                selectedValue={campaignTypeSelected}
                setSelectedValue={setCampaignTypeSelected}
            />


            {loading ? (
                <Spin indicator={antIcon} />
            ) : (
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }}
                    dataSource={campaignsFiltered}
                    locale={{
                        emptyText:
                            "Não há campanhas para este estabelecimento.",
                    }}
                    renderItem={(item: campaign) => {
                        var date = new Date(item.endAt);
                        var day = date.getDate() + 1;
                        var month = date.getMonth() + 1;
                        var year = date.getFullYear();

                        const fixedValue =
                            item.limitMaxValue === item.limitMinValue
                                ? true
                                : false;
                        const promotionalValue = fixedValue
                            ? (
                                (item.limitMaxValue / 100) *
                                (item.bonus / 100 + 1)
                            )
                                .toFixed(2)
                                .toString()
                                .replace(".", ",")
                            : (item.limitMaxValue / 100)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",");

                        return (
                            <List.Item key={item.id}>
                                <Card
                                    hoverable
                                    style={{ borderRadius: 10 }}
                                    onClick={() => {
                                        props.history.push(
                                            "/campaigns/" + item.id
                                        );
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {item.limitMaxValue && fixedValue ? (
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        colors.blue,
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: 10,
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: 100,
                                                        color: "#fff",
                                                        opacity: "0.2",
                                                        fontFamily:
                                                            "Bebas Neue, cursive",
                                                        marginLeft: 10,
                                                        position: "absolute",
                                                        bottom: 10,
                                                    }}
                                                >
                                                    {item.bonus}
                                                </span>
                                                {item.bonus >= 100 ? (
                                                    <span
                                                        style={{
                                                            fontSize: 50,
                                                            color: "#fff",
                                                            fontFamily:
                                                                "Bebas Neue, cursive",
                                                            top: 46,
                                                            position:
                                                                "relative",
                                                            left: 5,
                                                        }}
                                                    >
                                                        {item.bonus
                                                            ? parseInt(
                                                                item.bonus
                                                            )
                                                            : ""}
                                                        %
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            fontSize: 62,
                                                            color: "#fff",
                                                            fontFamily:
                                                                "Bebas Neue, cursive",
                                                            top: 33,
                                                            position:
                                                                "relative",
                                                            left: 17,
                                                        }}
                                                    >
                                                        {item.bonus
                                                            ? parseInt(
                                                                item.bonus
                                                            )
                                                            : ""}
                                                        %
                                                    </span>
                                                )}
                                            </div>
                                        ) : item.voucher ? (
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        colors.lightPurple,
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: 10,
                                                }}
                                            >
                                                {item.bonus >= 100 ? (
                                                    <span
                                                        style={{
                                                            fontSize: 100,
                                                            color: "#fff",
                                                            opacity: "0.2",
                                                            fontFamily:
                                                                "Bebas Neue, cursive",
                                                            position:
                                                                "absolute",
                                                        }}
                                                    >
                                                        {item.bonus}
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            fontSize: 100,
                                                            color: "#fff",
                                                            opacity: "0.2",
                                                            fontFamily:
                                                                "Bebas Neue, cursive",
                                                            marginLeft: 10,
                                                            position:
                                                                "absolute",
                                                            bottom: 10,
                                                        }}
                                                    >
                                                        {item.bonus}
                                                    </span>
                                                )}
                                                {item.bonus >= 100 ? (
                                                    <span
                                                        style={{
                                                            fontSize: 50,
                                                            color: "#fff",
                                                            fontFamily:
                                                                "Bebas Neue, cursive",
                                                            top: 46,
                                                            position:
                                                                "relative",
                                                            left: 5,
                                                        }}
                                                    >
                                                        {item.bonus
                                                            ? parseInt(
                                                                item.bonus
                                                            )
                                                            : ""}
                                                        %
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            fontSize: 62,
                                                            color: "#fff",
                                                            fontFamily:
                                                                "Bebas Neue, cursive",
                                                            top: 33,
                                                            position:
                                                                "relative",
                                                            left: 17,
                                                        }}
                                                    >
                                                        {item.bonus
                                                            ? parseInt(
                                                                item.bonus
                                                            )
                                                            : ""}
                                                        %
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        colors.lightGreen,
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: 10,
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: 100,
                                                        color: "#fff",
                                                        opacity: "0.2",
                                                        fontFamily:
                                                            "Bebas Neue, cursive",
                                                        marginLeft: 10,
                                                        position: "absolute",
                                                        bottom: 10,
                                                    }}
                                                >
                                                    DOAÇÃO
                                                </span>
                                                <span
                                                    style={{
                                                        fontSize: 50,
                                                        color: "#fff",
                                                        fontFamily:
                                                            "Bebas Neue, cursive",
                                                        position: "relative",
                                                        width: "15%",
                                                        lineHeight: 1,
                                                        left: -2,
                                                    }}
                                                >
                                                    DOA- ÇÃO
                                                </span>
                                            </div>
                                        )}

                                        <div style={styles.campaignName}>
                                            <span>{item.title} </span>
                                            {item.validDate ? (
                                                <p
                                                    style={
                                                        styles.campaignExpiringDate
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Validade:{" "}
                                                    </span>{" "}
                                                    {day +
                                                        "/" +
                                                        month +
                                                        "/" +
                                                        year}
                                                </p>
                                            ) : null}
                                            {fixedValue && item.limitMaxValue ? (
                                                <p
                                                    style={
                                                        styles.campaignExpiringDate
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Consuma:{" "}
                                                    </span>{" "}
                                                    {"R$: " + promotionalValue}
                                                </p>
                                            ) : null}
                                            {fixedValue && item.limitMaxValue ? (
                                                <p
                                                    style={
                                                        styles.campaignExpiringDate
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Pague apenas:{" "}
                                                    </span>
                                                    {"R$: " +
                                                        (
                                                            item.limitMaxValue /
                                                            100
                                                        )
                                                            .toFixed(2)
                                                            .toString()
                                                            .replace(
                                                                ".",
                                                                ","
                                                            )}{" "}
                                                </p>
                                            ) : null}
                                            {!fixedValue &&
                                                item.limitMinValue ? (
                                                <p
                                                    style={
                                                        styles.campaignExpiringDate
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Mínimo:{" "}
                                                    </span>
                                                    {"R$: " +
                                                        (
                                                            item.limitMinValue /
                                                            100
                                                        )
                                                            .toFixed(2)
                                                            .toString()
                                                            .replace(
                                                                ".",
                                                                ","
                                                            )}{" "}
                                                </p>
                                            ) : null}
                                            {!fixedValue &&
                                                item.limitMaxValue ? (
                                                <p
                                                    style={
                                                        styles.campaignExpiringDate
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Máximo:{" "}
                                                    </span>
                                                    {"R$: " +
                                                        (
                                                            item.limitMaxValue /
                                                            100
                                                        )
                                                            .toFixed(2)
                                                            .toString()
                                                            .replace(
                                                                ".",
                                                                ","
                                                            )}{" "}
                                                </p>
                                            ) : null}
                                            <p style={styles.campaignType}>
                                                {item.oneTimeRedeem
                                                    ? "Resgate único"
                                                    : item.partialRedeem
                                                        ? "Resgate parcial"
                                                        : ""}
                                            </p>
                                            <p style={styles.convenienceFee}>
                                                <span
                                                    style={{ fontWeight: 700 }}
                                                >
                                                    Taxa de conveniência:{" "}
                                                </span>
                                                {item.convenienceFee
                                                    ? item.convenienceFee
                                                        .toString()
                                                        .replace(".", ",") +
                                                    "%"
                                                    : "0%"}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                width: "15%",
                                                textAlign: "right",
                                            }}
                                        >
                                            {/* <span style={styles.campaignValue}>{item.voucher ? item.limitMaxValue && fixedValue ? "R$ " + parseFloat(item.limitMaxValue / 100).toFixed(2).toString().replace(".", ",") : item.bonus ? item.bonus.toFixed(1).replace(".", ",") + "% " : "" :  ""}</span> */}
                                        </div>
                                    </div>
                                    <p style={styles.detailText}>
                                        {"Ver detalhes >"}
                                    </p>
                                </Card>
                            </List.Item>
                        );
                    }}
                />
            )}
        </Modal>
    );
};

export default CampaignsModal;
