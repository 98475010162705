import { colors } from "../../colors";

export const styles = {
    root: {
        display: "flex",
        flexDirection: "column",
        padding: "2%",
        fontFamily: "Poppins, sans-serif",
        color: colors.darkPurple,
        border: "none",
        alignItems: "center",
        justifyContent: "center",
    } as unknown as React.CSSProperties,
    content: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
    } as unknown as React.CSSProperties,
    header: {
        width: "100%",
        alignText: "left",
    },
    logoSuccess: {
        marginTop: "5%",
        width: "20%",
    },
    detailText: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontDisabledColor,
        fontWeight: 700,
        textAlign: "right",
        fontSize: 16,
        style: "normal",
    } as unknown as React.CSSProperties,
    button: {
        backgroundColor: colors.lightPurple,
        border: "none",
        color: "#fff",
        fontWeight: "bold",
        fontFamily: "Poppins, sans-serif",
        width: "65%",
        height: "6vh",
        fontSize: "2vh",
        marginTop: "4%",
        marginBottom: "4%",
    } as unknown as React.CSSProperties,
    successButton: {
        backgroundColor: colors.lightPurple,
        border: "none",
        color: "#fff",
        fontWeight: "bold",
        fontFamily: "Poppins, sans-serif",
        width: "20%",
        height: "4.5vw",
        fontSize: "1.0vw",
        marginTop: "4%",
        marginBottom: "4%",
    } as unknown as React.CSSProperties,
    successTitle: {
        fontWeight: 400,
        fontSize: "3vh",
        marginTop: "0.5%",
    },
    boldTitle: {
        fontWeight: "900",
        fontSize: "3vh",
    } as unknown as React.CSSProperties,
    transactionDescription: {
        marginTop: "2%",
        fontSize: "1.8vh",
        color: colors.fontDisabledColor,
        width: "20%",
        textAlign: "left",
    } as unknown as React.CSSProperties,
};
