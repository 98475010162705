import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from "./App";
import config from "./config";
import "antd/dist/antd.css";
import { isDevelopment } from "./services/environment";

Sentry.init({ dsn: `${config.sentryDsn}` });

if (!isDevelopment()) {
    Sentry.init({
        dsn: config.sentryDsn,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
