import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import Logo from "../../../components/Logo";
import { styles, HeaderCard } from "./styles";
import { colors } from "../../../colors";
import { translate } from "../../../config/i18n/index.tsx";

export default () => {
  const history = useHistory();

  return (
    <HeaderCard>
      <Logo
        src={require("../../../assets/fanapp-logo-cromatica-horizontal.png")}
      />
    </HeaderCard>
  );
};
