import { colors } from "../../colors";
import TabsComponent from '../Tabs';
import styled from 'styled-components';

export const Tabs = styled(TabsComponent)`
    margin-bottom: 32px;
`;

export const styles = {
    logo: {
        width: "15%",
        height: "15%",
    },
    campaignTitle: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 20,
        fontWeight: "1000",
        marginTop: "4%",
    } as unknown as React.CSSProperties,
    campaignSubtitle: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 16,
        fontWeight: "800",
        color: colors.fontDisabledColor,
        marginBottom: "4%",
    } as unknown as React.CSSProperties,
    campaignName: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 18,
        fontWeight: "1000",
        color: colors.fontRegularColor,
        marginLeft: "-20%",
        width: "45%",
    } as unknown as React.CSSProperties,
    campaignValue: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 18,
        fontWeight: 1000,
        color: colors.lightPurple,
    },
    campaignExpiringDate: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontDisabledColor,
        fontSize: 12,
        fontWeight: 500,
    },
    campaignType: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontDisabledColor,
        fontSize: 12,
        fontWeight: 500,
    },
    convenienceFee: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontDisabledColor,
        fontSize: 12,
        fontWeight: 500,
    },
    detailText: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontDisabledColor,
        fontWeight: 700,
        textAlign: "right",
        fontSize: 16,
        style: "normal",
    } as unknown as React.CSSProperties,
    backgroundValue: {
        fontSize: "25%",
        fontFamily: "BebasNeue-Regular",
    },
    donationBackgroundValue: {
        fontSize: "25%",
        fontFamily: "BebasNeue-Regular",
    },
    value: {
        fontSize: "17.5%",
        fontFamily: "BebasNeue-Regular",
        bottom: -15,
    },
    donationText: {
        fontSize: "17.5%",
        left: -3,
        fontFamily: "BebasNeue-Regular",
        bottom: -10,
    },
};
