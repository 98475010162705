import React from "react";
import PropTypes from "prop-types";
import { FiX } from "react-icons/fi";

import ErrorMessageStyle from "./styles";
import Button from "../Button";

const ErrorMessage = (props) => {
    const { errorMessage, onClose } = props;
    return (
        <ErrorMessageStyle>
            <span>{errorMessage}</span>
            <Button onClick={onClose}>
                <FiX color="#ffffff" size={14} />
            </Button>
        </ErrorMessageStyle>
    );
};

ErrorMessage.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ErrorMessage;
