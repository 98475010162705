import React, { useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import { colors } from "../../colors";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "antd";
import { styles } from "./styles";
import { sendDangerNotification } from "../../tools/notifications";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { translate } from "../../config/i18n/index";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
    input: {
        borderColor: "#fff !important",
        width: "75%",
        marginTop: "5%",
        border: "none",
        color: colors.fontDisabledColor,
    },
    contentContainer: {
        margin: "4%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "60%",
    },
    mobileContentContainer: {
        margin: "4%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
    },
    title: {
        color: colors.fontRegularColor,
        fontSize: "2.8vh",
    },
    inputText: {
        fontFamily: "Poppins, sans-serif",
        textAlign: "right",
        color: colors.fontDisabledColor,
    },
}));

const Confirmation = (props) => {
    const classes = useStyles();
    const { campaign } = props;
    const [value, setValue] = useState<number>(
        props.fixedValue && props.campaign.limitMaxValue
            ? campaign.limitMaxValue / 100
            : 0
    );

    const total =
        value.toString().length > 0
            ? campaign.convenienceFee
                ? (
                    parseFloat(value.toString().replace(",", ".")) *
                    (campaign.convenienceFee / 100 + 1)
                )
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                : parseFloat(value.toString().replace(",", "."))
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
            : "0,00";

    const totalPayment =
        value.toString().length > 0
            ? parseFloat(value.toString().replace(",", "."))
                .toFixed(2)
                .toString()
                .replace(".", ",")
            : "0,00";

    const [windowSize, setWindowSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        });
    }, []);

    return (
        <div
            className={
                windowSize.width < 600
                    ? classes.mobileContentContainer
                    : classes.contentContainer
            }
        >
            <h1 className={classes.title}>
                {translate("checkout.confirmation_title")}
            </h1>
            <form noValidate autoComplete="off">
                <CurrencyTextField
                    label={translate("checkout.value_confirmation")}
                    variant="standard"
                    value={value}
                    currencySymbol="R$"
                    minimumValue="0"
                    inputProps={{
                        min: 0,
                        style: {
                            textAlign: "right",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "3vw",
                            border: "none",
                            paddingBottom: 25,
                            paddingTop: 25
                        },
                    }}
                    disabled={
                        campaign.limitMaxValue === campaign.limitMinValue &&
                            campaign.limitMaxValue
                            ? true
                            : false
                    }
                    className={classes.input}
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => {
                        setValue(value);
                    }}
                />
            </form>
            <div style={{ marginTop: "3%" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "75%",
                        marginLeft: "12%",
                    }}
                >
                    <div style={{ textAlign: "left" }}>
                        <h2
                            style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "normal",
                                color: "#060A4D",
                                fontSize: "1.3vh",
                            }}
                        >
                            Total
                        </h2>
                        <span
                            style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "normal",
                                color: colors.fontDisabledColor,
                                fontSize: "0.8em",
                            }}
                        >
                            {translate("checkout.fee_disclaimer")}{" "}
                            {campaign.convenienceFee
                                ? campaign.convenienceFee
                                    .toString()
                                    .replace(".", ",") + "%"
                                : "0%"}{" "}
                            {translate("checkout.fee_disclaimer_tax")}
                        </span>
                    </div>
                    <h1
                        style={{
                            fontFamily: "Poppins, sans-serif",
                            color: "#060A4D",
                            fontSize: "1.3vh",
                        }}
                    >
                        R$ {total}
                    </h1>
                </div>
            </div>
            <Button
                style={styles.button}
                onClick={() => {
                    if (!value || value === 0)
                        sendDangerNotification(
                            "O campo valor precisa ser preenchido"
                        );
                    else if (
                        campaign.limitMaxValue &&
                        value * 100 > campaign.limitMaxValue
                    )
                        sendDangerNotification(
                            "O valor digitado ultrapassa o limite máximo da campanha."
                        );
                    else if (
                        campaign.limitMinValue &&
                        value * 100 < campaign.limitMinValue
                    )
                        sendDangerNotification(
                            "O valor digitado é o menor que o valor mínimo da campanha."
                        );
                    else {
                        props.storeValue(
                            parseFloat(
                                totalPayment.toString().replace(",", ".")
                            ) * 100
                        );
                        props.setStage();
                    }
                }}
            >
                {translate("checkout.confirm_button")}
            </Button>
            <br />
            <a
                href="https://www.dropbox.com/s/75gb4zf5yum7k4r/Termos%20de%20Uso%20e%20Pol%C3%ADtica%20de%20Privacidade%20-%20SouFan.pdf"
                style={{
                    fontWeight: 700,
                    marginTop: "-50%",
                    color: colors.grayPurple,
                    fontSize: "1.5vh",
                }}
            >
                {translate("checkout.use_terms")}
            </a>
        </div>
    );
};

export default Confirmation;
