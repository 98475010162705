import React, { useState } from "react";
import PropTypes from "prop-types";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import { Formik, Field } from "formik";

import LockScreenModalStyle from "./styles";
import { InputButtonRight } from "../InputButton";
import SubmitButton from "../SubmitButton";
import ErrorMessage from "../ErrorMessage";
import Form from "../Form";

const LockScreenModal = (props) => {
    const {
        setLock,
        checkPassword,
        title,
        placeholder,
        errorMessage,
        observationMessage,
        logo,
        maxLength,
        className,
        style,
    } = props;

    const [hasError, setHasError] = useState(false);
    return (
        <LockScreenModalStyle style={style} className={className}>
            {hasError ? (
                <ErrorMessage
                    errorMessage={errorMessage}
                    onClose={() => setHasError(false)}
                />
            ) : (
                <></>
            )}
            {logo ? <img src={logo} alt="Logo" /> : <></>}
            <AiFillLock color="#333333" size={40} />
            <h3>{title}</h3>
            <Formik
                initialValues={{
                    password: "",
                }}
                onSubmit={({ password }) => {
                    checkPassword(password)
                        .then((result) => {
                            if (result) {
                                setLock(false);
                            } else {
                                throw Error("Incorrect password");
                            }
                        })
                        .catch(() => setHasError(true));
                }}
            >
                {() => (
                    <Form>
                        <InputButtonRight>
                            <Field
                                name="password"
                                placeholder={placeholder}
                                maxLength={maxLength}
                                inputMode="text"
                                autoFocus
                            />
                            <SubmitButton>
                                <AiFillUnlock color="#ffffff" size={14} />
                            </SubmitButton>
                        </InputButtonRight>
                        {observationMessage ? (
                            <span className="observation">
                                {observationMessage}
                            </span>
                        ) : (
                            <></>
                        )}
                    </Form>
                )}
            </Formik>
        </LockScreenModalStyle>
    );
};

LockScreenModal.propTypes = {
    setLock: PropTypes.func.isRequired,
    checkPassword: PropTypes.func.isRequired,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    observationMessage: PropTypes.string,
    logo: PropTypes.string,
    maxLength: PropTypes.number,
    className: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
};

LockScreenModal.defaultProps = {
    title: "Locked screen",
    placeholder: "Password",
    errorMessage: "Incorrect password",
    observationMessage: null,
    logo: null,
    maxLength: 30,
    className: "",
    style: {},
};

export default LockScreenModal;
