import React from "react";
import { Button } from "antd";
import { colors } from "../../colors";
import { styles } from "./styles";
const Success = (props) => {
    const name = props.location.state.campaign.establishment.fancyName
        ? props.location.state.campaign.establishment.fancyName
        : props.location.state.campaign.establishment.name
        ? props.location.state.campaign.establishment.name
        : props.location.state.campaign.establishment.parnerName
        ? props.location.state.campaign.establishment.partnerName
        : "";

    return (
        <div style={styles.outerDiv}>
            <div style={styles.root}>
                <div style={styles.header}>
                    <a onClick={() => props.history.push("/home")}>← Voltar</a>
                </div>
                <div style={styles.content}>
                    <img
                        src={require("../../assets/tick.png")}
                        alt="tick"
                        style={styles.logoSuccess}
                    ></img>
                    <h1 style={styles.successTitle}>
                        Transação efetuada com{" "}
                        <span style={styles.boldTitle}>sucesso!</span>
                    </h1>
                    <span style={styles.transactionDescription}>
                        O seu voucher no valor total de{" "}
                        <span
                            style={{
                                color: colors.lightPurple,
                                fontWeight: "bold",
                                fontSize: "2.5vh",
                            }}
                        >
                            R${" "}
                            {(props.location.state.value / 100)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",")}
                        </span>{" "}
                        <span
                            style={{
                                color: colors.lightPurple,
                                fontSize: "2.5vh",
                            }}
                        >
                            ({name})
                        </span>{" "}
                        será enviado por e-mail e também encontra-se disponível
                        no aplicativo.
                    </span>
                </div>
                <Button
                    style={styles.successButton}
                    onClick={() => {
                        props.history.push("/home");
                    }}
                >
                    voltar à home
                </Button>
            </div>
        </div>
    );
};

export default Success;
