import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    font-size: 16px;
    --primary-color: #ffffff;
    --alert-color: #ffc107;
    --warning-color: #fe6421;
    --danger-color: #f53d52;
    --font-color: #333333;
    --font-lighter-color: #666666;
    --font-lightest-color: #999999;
    --line-color: #eeeeee;
    --radius-size: 4px;
    --input-size: 40px;
    --button-size: 40px;
    --box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  * {
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
  }

  body {
    background: var(--primary-color);
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  body, input, button {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    color: var(--font-color);
  }

  button {
    outline: none;
    cursor: pointer;
  }
  button::-moz-focus-inner {
    border: 0;
  }

  a {
    color: var(--primary-dark-color, #551a8b);
    text-decoration: none;
    outline: none;
  }

  textarea:focus, input:focus{
    outline: none;
  }

  input:not([type=checkbox]):not([type=radio]) {
    height: var(--input-size);
    border: 1px solid var(--line-color);
    padding: 5px 15px;
    border-radius: var(--radius-size);
    font-size: 1rem;
    min-width: 1px;
    min-height: 1px;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
  }
  h2 {
    font-size: 1.7rem;
    font-weight: bold;
  }
  h3 {
    font-size: 1.2rem;
    // TODO: remover margin
    margin: 40px 0 20px;
  }

  p {
    line-height: 1.2rem;
  }

  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
`;
