import * as yup from "yup";
import { isCPF, isCNPJ } from "../lib/document-validator";
import { makeMinCurrency, makeMaxCurrency } from "../lib/currency-validator";
import isDate from "../lib/is-date-validator";
import creditCardExpiry from "../lib/credit-card-expiry-validator";

yup.addMethod(yup.string, "minCurrency", function cb(min, msg) {
  const message = msg || "Value is low";
  return this.test("min-currency", message, makeMinCurrency(min));
});

yup.addMethod(yup.string, "maxCurrency", function cb(max, msg) {
  const message = msg || "Value is high";
  return this.test("max-currency", message, makeMaxCurrency(max));
});

yup.addMethod(yup.string, "isDate", function cb(msg) {
  const message = msg || "Invalid date";
  return this.test("is-date", message, isDate);
});

yup.addMethod(yup.string, "creditCardExpiry", function cb(msg) {
  const message = msg || "Invalid expiration date";
  return this.test("credit-card-expiry", message, creditCardExpiry);
});

yup.addMethod(yup.string, "cpf", function cb(msg) {
  const message = msg || "Invalid CPF number";
  return this.test("cpf", message, isCPF);
});

yup.addMethod(yup.string, "cnpj", function cb(msg) {
  const message = msg || "Invalid CNPJ number";
  return this.test("cnpj", message, isCNPJ);
});

export default yup;
