import React from 'react';

import { Container } from './styles';

interface Tab {
    [key: string]: any;
}

interface TabProps {
    tabs: Array<Tab>;
    selectedValue: string;
    setSelectedValue: (selectedValue) => void;
}

const Tabs = ({ tabs, selectedValue, setSelectedValue, ...rest }: TabProps) => {

    function handleClick(x) {
        setSelectedValue(x);
    }

    return (
        <Container {...rest}>
            <ul>
                {tabs.map(x => (
                    <li onClick={() => handleClick(x.value)} className={selectedValue === x.value ? 'selected' : ''}>{x.description}</li>
                ))}
            </ul>
        </Container>
    );
};

export default Tabs;
