import styled from 'styled-components';

export const Container = styled.div`
    ul {
        display: flex;
    }

    li {
        font-size: 16px;
        padding: 0 15px;
        color: #C0B8D0;
        font-weight: 500;
        margin-right: 20px;
        cursor: pointer;
    }

    li.selected {
        font-weight: bold;
        border-bottom: 2px solid #09CAF0;
        color: #09CAF0;
    }
`;
