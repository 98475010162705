import styled from "styled-components";

export default styled.div`
    --size: 2rem;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--warning-color);
    color: #ffffff;
    width: 100%;
    height: var(--size);
    margin-bottom: 30px;
    border-radius: var(--radius-size);

    button {
        position: absolute;
        right: 0;
        padding: 0;
        height: var(--size);
        width: var(--size);
        background-color: rgba(0, 0, 0, 0);
    }
`;
