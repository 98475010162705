import { cpf, cnpj } from "cpf-cnpj-validator";

export const isCPF = (value) => {
  if (!value) {
    return true;
  }

  return cpf.isValid(value);
};

export const isCNPJ = (value) => {
  if (!value) {
    return true;
  }

  return cnpj.isValid(value);
};
