import React from "react";

import { ThemeProvider } from "styled-components";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import theme from "./styles/theme";
import Routes from "./routes";
import ResetStyle from "./styles/reset";
import GlobalStyle from "./styles/global";

import "./config/i18n/index.tsx";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

function App() {
    const { i18n } = useTranslation();

    if (!cookies.get("language")) {
        const defaultLanguage = i18n.language;
        cookies.set("language", defaultLanguage);
    } else {
        i18n.changeLanguage(cookies.get("language"));
    }

    return (
        <ThemeProvider theme={theme}>
            <ReactNotification />
            <Routes />
            <ResetStyle />
            <GlobalStyle />
        </ThemeProvider>
    );
}

export default App;
