import {
    sendDangerNotification,
    sendSuccessNotification,
} from "../../tools/notifications";

export const sendRegisterSuccessNotification = () => {
    return sendSuccessNotification(
        "O estabelecimento foi cadastrado com sucesso. Verifique se recebeu o email de confirmação."
    );
};

export const sendRegisterDuplicatedFailureNotification = (name) => {
    return sendDangerNotification(
        `Já existe um estabelecimento cadastrado com o nome: ${name}`
    );
};

export const sendLogoUploadFailureNotification = (email) => {
    return sendDangerNotification(
        `Ocorreu um erro no envio da logo, mas o cadastro foi realizado com successo. Para resolver o problema da logo entre em contato com ${email}.`
    );
};
