import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import IdleTimer from "react-idle-timer";

const LockScreenContainer = (props) => {
    const { children, ui, timeout, onScreenLocked, onScreenUnlocked } = props;
    let { isLocked, setIsLocked } = props;

    const [defaultIsLocked, setDefaultIsLocked] = useState(false);
    if (isLocked === undefined || setIsLocked === undefined) {
        isLocked = defaultIsLocked;
        setIsLocked = setDefaultIsLocked;
    }

    const init = useRef(false);

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            return;
        }

        if (isLocked) {
            onScreenLocked();
        } else {
            onScreenUnlocked();
        }
    }, [isLocked, onScreenLocked, onScreenUnlocked]);

    const handleOnIdle = () => {
        if (!isLocked) setIsLocked(true);
    };

    const baseStyle = {
        height: "100vh",
        userSelect: "none",
        pointerEvents: "none",
        overflow: "hidden",
    };
    const effectStyles = {
        smallBlur: {
            filter: "blur(4px)",
        },
        largeBlur: {
            filter: "blur(100px)",
        },
        opaque: {
            filter: "brightness(0.2)",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
    };
    const getDefaultStyles = () => {
        const defaultStyle = props.className || {
            ...baseStyle,
            ...effectStyles[props.effect],
        };
        return isLocked ? defaultStyle : {};
    };
    const getClassNames = () => {
        return isLocked ? props.className : "";
    };

    const renderLockScreenUi = () => {
        if (isLocked) return ui(setIsLocked);
        return <></>;
    };

    const renderIdleTimer = () => {
        if (timeout)
            return <IdleTimer onIdle={handleOnIdle} timeout={timeout} />;
        return <></>;
    };

    return (
        <>
            {renderIdleTimer()}
            <div style={getDefaultStyles()} className={getClassNames()}>
                {children}
            </div>
            {renderLockScreenUi()}
        </>
    );
};

LockScreenContainer.defaultProps = {
    className: "",
    effect: "largeBlur",
    timeout: 0,
    isLocked: undefined,
    setIsLocked: undefined,
    onScreenLocked: () => {},
    onScreenUnlocked: () => {},
};

LockScreenContainer.propTypes = {
    children: PropTypes.element.isRequired,
    ui: PropTypes.func.isRequired,
    className: PropTypes.string,
    effect: PropTypes.oneOf(["smallBlur", "largeBlur", "opaque"]),
    timeout: PropTypes.number,
    isLocked: PropTypes.bool,
    setIsLocked: PropTypes.func,
    onScreenLocked: PropTypes.func,
    onScreenUnlocked: PropTypes.func,
};

export default LockScreenContainer;
