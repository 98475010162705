import React, { useState, useEffect } from "react";

import api from "../../../services/api";
import { LogoContainer, styles } from "./styles";
import CardContainer from "../../../components/Card";
import CampaignsModal from "../../../components/CampaignsModal";
import { Card, List, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { colors } from "../../../colors";
import { translate } from "../../../config/i18n/index";

interface Sellers {
    [key: string]: any;
}

export const FeaturedSellers = (props) => {
    const [showModal, setModal] = useState(false);
    const [sellers, setSellers] = useState([]);
    const [establishmentId, setEstablishmentId] = useState("");
    const [establishment, setEstablishment] = useState({});
    const [loading, setLoading] = useState(true);
    const [mouseOver, setMouseOver] = useState(false);
    const [showScroll, setShowScroll] = useState(false);
    const [location, setLocation] = useState('');

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("scroll", checkScrollTop);

    const antIcon = (
        <LoadingOutlined
            style={{ fontSize: 24, color: colors.purpleColor }}
            spin
        />
    );

    useEffect(() => {
        api.get("/v2/customer/establishments")
            .then((data) => {
                setSellers(data.data.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, []);

    const getFilteredArray = (array: Sellers[]) => {
        return array.filter((item: Sellers) => {
            return item.city.toLowerCase().includes(location.toLowerCase());
        })
    }

    return (
        <CardContainer>
            <p style={styles.title}>
                {translate("home.check_our_establishments")}
            </p>
            <div
                style={styles.inputContainer}
            >
                <span
                    style={styles.inputLabel}>
                    Localização
                </span>
                <input
                    placeholder="Qual cidade deseja pesquisar?"
                    style={styles.input}
                    value={location}
                    onChange={e => setLocation(e.target.value)}
                />
            </div>

            {loading ? (
                <Spin indicator={antIcon} />
            ) : (
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 6,
                    }}
                    dataSource={getFilteredArray(sellers)}
                    locale={{
                        emptyText:
                            "Não há campanhas para este estabelecimento.",
                    }}
                    renderItem={(item: Sellers) => {
                        let hover = false;

                        return (
                            <div
                                style={{
                                    cursor: "pointer",
                                    marginBottom: 45,
                                    position: "relative",
                                }}
                                onMouseEnter={() => {
                                    hover = true;
                                    setMouseOver(true);
                                }}
                                onMouseLeave={() => {
                                    hover = false;
                                    setMouseOver(false);
                                }}
                            >
                                <List.Item key={item.id}>
                                    <Card
                                        hoverable
                                        onClick={() => {
                                            setEstablishmentId(item.id);
                                            setEstablishment(item);
                                            setModal(true);
                                        }}
                                        style={{
                                            width: 270,
                                            height: 281,
                                        }}
                                        cover={
                                            <LogoContainer>
                                                {item.logo ? (
                                                    <img
                                                        src={item.logo.location}
                                                        alt="Logo"
                                                        style={{
                                                            marginTop: 70,
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        <img
                                                            src={require("../../../assets/notfound.png")}
                                                            alt="Não encontrado"
                                                            style={{
                                                                marginTop: 70,
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </LogoContainer>
                                        }
                                    ></Card>
                                    <div style={{ width: "85%" }}>
                                        <p
                                            style={{
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                fontSize: "20px",
                                                color:
                                                    mouseOver && hover
                                                        ? colors.lightPurple
                                                        : colors.darkGray,
                                                fontFamily:
                                                    "'Poppins', sans-serif",
                                                marginTop: 15,
                                            }}
                                        >
                                            {item.fancyName
                                                ? item.fancyName
                                                : item.name
                                                    ? item.name
                                                    : item.partnerName}
                                        </p>
                                        <p
                                            style={{
                                                textAlign: "center",
                                                fontSize: "15px",
                                                color: colors.fontDisabledColor,
                                                fontFamily:
                                                    "'Poppins', sans-serif",
                                                marginTop: 5,
                                            }}
                                        >
                                            ver mais
                                        </p>
                                    </div>
                                </List.Item>
                            </div>
                        );
                    }}
                />
            )}

            {showModal ? (
                <CampaignsModal
                    showModal={showModal}
                    setModal={() => setModal(false)}
                    establishmentId={establishmentId}
                    establishment={establishment}
                    history={props.history}
                />
            ) : null}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "space-between",
                    width: "95%",
                    justifyContent: "space-between",
                    marginTop: "2%",
                }}
            >
                <span
                    style={{
                        fontFamily: "Titillium Web",
                        fontSize: "1.5vh",
                        color: colors.grayPurple,
                    }}
                >
                    © SouFan - {translate("home.rights_statement")}{" "}
                    <a
                        href="https://www.dropbox.com/s/75gb4zf5yum7k4r/Termos%20de%20Uso%20e%20Pol%C3%ADtica%20de%20Privacidade%20-%20SouFan.pdf"
                        style={{ fontWeight: 700 }}
                    >
                        {translate("home.use_terms")}
                    </a>
                </span>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "10%",
                    }}
                >   <a href="https://www.instagram.com/soufantech/">
                        <img src={require("../../../assets/instagram.png")} alt='Rede Social' />
                    </a>
                    <a href="https://www.facebook.com/Soufan-100254345041332">
                        <img src={require("../../../assets/fb.png")} alt='Rede Social' />
                    </a>
                    <a href="https://www.linkedin.com/company/soufantech/">
                        <img src={require("../../../assets/linkedin.png")} alt='Rede Social' />
                    </a>
                </div>
            </div>
            {showScroll ? (
                <div style={{ width: "90%", position: "fixed" }}>
                    <div
                        style={{
                            display: "flex",
                            marginLeft: "100%",
                            bottom: "5%",
                            backgroundColor: "#f0eff3",
                            width: "5em",
                            height: "5em",
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 70,
                            cursor: "pointer",
                        }}
                        onClick={() => scrollTop()}
                    >
                        <div>
                            <img
                                src={require("../../../assets/arrow-up.png")}
                                alt="arrow-up"
                                style={{ position: "relative", width: "100%" }}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </CardContainer>
    );
};
