import {
    sendDangerNotification,
    sendSuccessNotification,
} from "../../tools/notifications";

export const sendRedeemSuccessNotification = (amount) => {
    return sendSuccessNotification(`Foi resgatado a quantia de ${amount}.`);
};

export const sendInsufficientFundsFailureNotification = () => {
    return sendDangerNotification("O valor especificado excede o disponível.");
};
