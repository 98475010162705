import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    FiCamera,
    FiCameraOff,
    FiMaximize2,
    FiMinimize2,
} from "react-icons/fi";
import QrReader from "react-qr-scanner";

import QrCodeReaderStyle from "./styles";
import { lengthProp } from "../../lib/parse-length-prop";

const QrCodeReader = (props) => {
    const { delay, facingMode, onError, onScan, customStyle } = props;
    let { handleChangeState, handleChangeZoom, state, zoom } = props;

    const [scannerState, setScannerState] = useState(true);
    const [scannerZoom, setScannerZoom] = useState(false);

    if (handleChangeState === undefined || state === undefined) {
        state = scannerState;
        handleChangeState = () => setScannerState(!scannerState);
    }
    if (handleChangeZoom === undefined || zoom === undefined) {
        zoom = scannerZoom;
        handleChangeZoom = () => setScannerZoom(!scannerZoom);
    }

    return (
        <QrCodeReaderStyle zoom={zoom} {...customStyle}>
            <div>
                <header>
                    <button type="button" onClick={handleChangeZoom}>
                        {zoom ? (
                            <FiMaximize2 color="#333333" size={14} />
                        ) : (
                            <FiMinimize2 color="#333333" size={14} />
                        )}
                    </button>
                    <button type="button" onClick={handleChangeState}>
                        {state ? (
                            <FiCamera color="#333333" size={14} />
                        ) : (
                            <FiCameraOff color="#333333" size={14} />
                        )}
                    </button>
                </header>
                {state ? (
                    <QrReader
                        delay={delay}
                        facingMode={facingMode}
                        onError={onError}
                        onScan={onScan}
                        style={{ objectFit: "cover" }}
                    />
                ) : (
                    <section>
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video />
                    </section>
                )}
                <span />
            </div>
        </QrCodeReaderStyle>
    );
};

QrCodeReader.propTypes = {
    delay: PropTypes.number,
    facingMode: PropTypes.oneOf(["front", "rear"]),
    onError: PropTypes.func.isRequired,
    onScan: PropTypes.func.isRequired,
    handleChangeState: PropTypes.func,
    handleChangeZoom: PropTypes.func,
    state: PropTypes.bool,
    zoom: PropTypes.bool,
    customStyle: PropTypes.shape({
        size: PropTypes.oneOfType([...lengthProp]),
        minSize: PropTypes.oneOfType([...lengthProp]),
        maxSize: PropTypes.oneOfType([...lengthProp]),
        cornerEdgeSpace: PropTypes.oneOfType([...lengthProp]),
        cornerEdgeBorder: PropTypes.oneOfType([...lengthProp]),
        cornerEdgeColor: PropTypes.string,
        backgroundColor: PropTypes.string,
    }),
};

QrCodeReader.defaultProps = {
    delay: 300,
    facingMode: "rear",
    handleChangeState: undefined,
    handleChangeZoom: undefined,
    state: undefined,
    zoom: undefined,
    customStyle: {},
};

export default QrCodeReader;
