import PropTypes from "prop-types";

const parseLengthProp = (prop, index = 0, raw = false) => {
  const items = [].concat(prop);

  let item = items[index];
  if (!item) {
    item = items[items.length - 1];
  }

  if (typeof item === "number") {
    if (raw) return item;
    return `${item}px`;
  }
  if (typeof item === "string") {
    return item;
  }
  return "";
};

export const lengthProp = [PropTypes.number, PropTypes.string];
export const lengthArrayProp = [
  PropTypes.arrayOf(PropTypes.number),
  PropTypes.arrayOf(PropTypes.string),
];

export default parseLengthProp;

// console.log(parseLengthProp());
// console.log(parseLengthProp(2));
// console.log(parseLengthProp(2, 0));
// console.log(parseLengthProp(2.3, 0));
// console.log(parseLengthProp(2, 0, true));
// console.log(parseLengthProp(2.3, 0, true));
// console.log(parseLengthProp('2rem', 0));
// console.log(parseLengthProp([1, 2, 3, 4]));
// console.log(parseLengthProp([1, 2, 3, 4], 1));
// console.log(parseLengthProp([1, 2, 3, 4], 10));
// console.log(parseLengthProp(['1rem', '2rem', '3rem', '4rem'], 1));
// console.log(parseLengthProp(['1rem', '2rem', '3rem', '4rem'], 10));
// console.log(parseLengthProp([1, '2rem', '3rem', '4rem'], 1));
