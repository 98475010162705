import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { FaSpinner } from "react-icons/fa";
import Dropzone from "react-dropzone";
import { Formik, ErrorMessage, Field } from "formik";
import axios from "axios";
import placeholderImage from "../../assets/image-folder.svg";
import config from "../../config";
import api from "../../services/api";
import extractErrorFromAxios from "../../lib/extract-error-from-axios";
import {
    maskCNPJ,
    maskCPF,
    maskCEP,
    maskStateAbbreviation,
    maskBirthdate,
    maskPhone,
} from "../../lib/input-masks";
import { objFilter } from "../../lib/object";
import Body from "../../components/Body";
import Container from "../../components/Container";
import Logo from "../../components/Logo";
import ImageUpload from "../../components/ImageUpload";
import Form from "../../components/Form";
import FormSection from "../../components/FormSection";
import InputError from "../../components/InputError";
import MaskedField from "../../components/MaskedField";
import SubmitButton from "../../components/SubmitButton";
import validator from "./validator";
import {
    sendRegisterSuccessNotification,
    sendLogoUploadFailureNotification,
} from "./notifications";
import {
    sendGenericFailureNotification,
    sendIncorrectFormFieldsFailureNotification,
    sendDangerNotification,
} from "../../tools/notifications";
import VerticalSpace from "../../components/VerticalSpace";

const startConsultant = (props) => {
    const { location } = props;

    const consultant = queryString.parse(location.search).consultant || "";

    return consultant;
};

const resetableState = {
    logoData: "",
    logoPreview: "",
    pickerVisible: false,
    brandColor: "",
    state: "",
    line1: "",
    city: "",
    cep: "",
    neighborhood: "",
};

export default class Main extends Component {
    static propTypes = {
        location: PropTypes.shape({
            // ?consultant=1a2b3c4d
            search: PropTypes.string,
        }).isRequired,
    };

    state = {
        ...resetableState,
        consultant: startConsultant(this.props),
    };

    handleImageDrop = (acceptedFiles) => {
        const logoData = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ logoData, logoPreview: reader.result });
        };
        reader.readAsDataURL(logoData);
    };

    handleUploadImage = async (endpoint) => {
        const formData = new FormData();
        const { logoData } = this.state;
        formData.append("logo", logoData);
        await api.post(endpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    };

    handleColorPickerToggle = () => {
        const { pickerVisible } = this.state;
        this.setState({ pickerVisible: !pickerVisible });
    };

    handleSubmit = async (values, { resetForm }) => {
        const { logoData, consultant } = this.state;

        const payloadEstablishment = {
            ...values,
            document: values.document.replace(/[^\d]/g, ""),
            postalCode: this.state.cep.replace(/[^\d]/g, ""),
            partnerDocument: values.partnerDocument.replace(/[^\d]/g, ""),
            agencyDocument: values.agencyDocument.replace(/[^\d]/g, ""),
            partnerPhone: "+55" + values.partnerPhone.replace(/[^\d]/g, ""),
            branch: values.branch.replace(/[^\d]/g, ""),
            accountNumber: values.accountNumber.replace(/[^\d]/g, ""),
            providerId: config.providerId,
            consultant,
            line1: this.state.line1,
            neighborhood: this.state.neighborhood,
            city: this.state.city,
            state: this.state.state,
        };
        const nonNullPayloadEstablishment = objFilter(
            payloadEstablishment,
            (value) => !!value[1]
        );

        try {
            const { data: apiResponse } = await api.put(
                "/v2/trade/establishments",
                nonNullPayloadEstablishment
            );

            try {
                if (logoData) {
                    this.handleUploadImage(
                        `/v2/trade/establishments/${apiResponse.data.id}/logo`
                    );
                }
                sendRegisterSuccessNotification();
            } catch {
                sendLogoUploadFailureNotification(config.defaultSeller.email);
            }

            resetForm();
            this.setState({ ...resetableState });
        } catch (e: any) {
            const { error, code, message } = extractErrorFromAxios(e);

            if (code === "InvalidInput") {
                sendIncorrectFormFieldsFailureNotification(
                    error.extensions.invalidInput
                );
            } else if (code === "NotUnique") {
                sendDangerNotification(
                    "Esse estabelecimento já foi registrado. Verifique os documentos e tente novamente."
                );
            } else if (code === "Required") {
                sendDangerNotification(
                    "Verifique se todos os campos foram preenchidos."
                );
            } else if (message === "Agency not found") {
                sendDangerNotification(
                    "O CPF digitado não corresponde a um agente cadastrado no FanApp."
                );
            } else {
                sendGenericFailureNotification(
                    config.defaultSeller.email,
                    e.message
                );
            }
        }
    };

    getAddress(cep) {
        axios
            .get("https://brasilapi.com.br/api/cep/v1/" + cep)
            .then((response) => {
                this.setState({
                    ...this.state,
                    line1: response.data.street,
                    neighborhood: response.data.neighborhood,
                    state: response.data.state,
                    city: response.data.city,
                });
                return {
                    line1: response.data.street,
                    neighborhood: response.data.neighborhood,
                    state: response.data.state,
                    city: response.data.city,
                };
            });
        return {
            line1: "",
            neighborhood: "",
            state: "",
            city: "",
        };
    }

    render() {
        const { logoPreview, pickerVisible, brandColor } = this.state;

        return (
            <Body primaryColor={config.defaultSeller.color}>
                <Container>
                    <Logo
                        src={require("../../assets/fanapp-logo-cromatica.png")}
                    />
                    <VerticalSpace />
                    <h1>Cadastro de comerciante</h1>
                    <Formik
                        initialValues={{
                            fancyName: "",
                            website: "",
                            name: "",
                            document: "",
                            line2: "",
                            line3: "",
                            bankName: "",
                            branch: "",
                            accountNumber: "",
                            partnerName: "",
                            partnerDocument: "",
                            partnerBirthdate: "",
                            partnerPhone: "",
                            partnerEmail: "",
                            partnerPassword: "",
                            agencyDocument: "",
                        }}
                        validationSchema={validator}
                        onSubmit={this.handleSubmit}
                    >
                        {(props) => {
                            return (
                                <Form>
                                    <FormSection>
                                        <Field
                                            mask={maskCPF}
                                            guide={false}
                                            name="agencyDocument"
                                            placeholder="CPF do Agente Responsável"
                                            inputMode="numeric"
                                            component={MaskedField}
                                        />
                                        <ErrorMessage
                                            name="agencyDocument"
                                            component={InputError}
                                        />
                                    </FormSection>
                                    <h3>
                                        Se identifique para o público (opcional)
                                    </h3>
                                    <FormSection>
                                        <Dropzone
                                            onDrop={this.handleImageDrop}
                                            accept="image/*"
                                            multiple={false}
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive,
                                            }) => {
                                                return (
                                                    <ImageUpload
                                                        {...getRootProps()}
                                                        isDragActive={
                                                            isDragActive
                                                        }
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />
                                                        {logoPreview ? (
                                                            <img
                                                                src={
                                                                    logoPreview
                                                                }
                                                                alt="Logo do estabelecimento"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    placeholderImage
                                                                }
                                                                alt="Placeholder de logo"
                                                            />
                                                        )}
                                                        {isDragActive ? (
                                                            <p>
                                                                Solte a imagem
                                                                aqui...
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                Solte sua logo
                                                                aqui ou clique
                                                                pra buscar
                                                            </p>
                                                        )}
                                                    </ImageUpload>
                                                );
                                            }}
                                        </Dropzone>
                                        <Field
                                            name="fancyName"
                                            placeholder="Nome fantasia ou apelido"
                                            maxLength="60"
                                            inputMode="text"
                                        />
                                        <ErrorMessage
                                            name="fancyName"
                                            component={InputError}
                                        />
                                        <Field
                                            name="website"
                                            placeholder="Website"
                                            inputMode="url"
                                        />
                                        <ErrorMessage
                                            name="website"
                                            component={InputError}
                                        />
                                    </FormSection>
                                    <h3>
                                        Informações da empresa (se aplicável)
                                    </h3>
                                    <FormSection>
                                        <Field
                                            className="upper"
                                            name="name"
                                            placeholder="Razão social"
                                            maxLength="60"
                                            inputMode="text"
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component={InputError}
                                        />
                                        <Field
                                            mask={maskCNPJ}
                                            guide={false}
                                            name="document"
                                            placeholder="CNPJ"
                                            inputMode="numeric"
                                            component={MaskedField}
                                            required={false}
                                        />
                                        <ErrorMessage
                                            name="document"
                                            component={InputError}
                                        />
                                    </FormSection>

                                    <h3>Endereço comercial</h3>
                                    <FormSection>
                                        <Field
                                            mask={maskCEP}
                                            guide={false}
                                            placeholder="CEP"
                                            id="cep"
                                            inputMode="numeric"
                                            component={MaskedField}
                                            value={this.state.cep}
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length >= 9
                                                ) {
                                                    this.getAddress(
                                                        e.target.value
                                                    );
                                                }
                                                this.setState({
                                                    cep: e.target.value,
                                                });
                                            }}
                                        />
                                        <ErrorMessage
                                            name="postalCode"
                                            component={InputError}
                                        />
                                        <Field
                                            placeholder="Logradouro (e.g. Rua, Avenida)"
                                            maxLength="60"
                                            inputMode="text"
                                            value={this.state.line1}
                                            onChange={(e) => {
                                                this.setState({
                                                    line1: e.target.value,
                                                });
                                            }}
                                        />
                                        <ErrorMessage
                                            name="line1"
                                            component={InputError}
                                        />
                                        <Field
                                            name="line2"
                                            placeholder="Número"
                                            maxLength="60"
                                            inputMode="text"
                                        />
                                        <ErrorMessage
                                            name="line2"
                                            component={InputError}
                                        />
                                        <Field
                                            name="line3"
                                            placeholder="Complemento (se aplicável)"
                                            maxLength="60"
                                            inputMode="text"
                                        />
                                        <ErrorMessage
                                            name="line3"
                                            component={InputError}
                                        />
                                        <Field
                                            placeholder="Bairro"
                                            maxLength="60"
                                            inputMode="text"
                                            value={this.state.neighborhood}
                                            onChange={(e) => {
                                                this.setState({
                                                    neighborhood:
                                                        e.target.value,
                                                });
                                            }}
                                        />
                                        <ErrorMessage
                                            name="neighborhood"
                                            component={InputError}
                                        />
                                        <Field
                                            id="city"
                                            placeholder="Cidade"
                                            maxLength="60"
                                            inputMode="text"
                                            value={this.state.city}
                                            disabled
                                        />
                                        <ErrorMessage
                                            name="city"
                                            component={InputError}
                                        />
                                        <Field
                                            mask={maskStateAbbreviation}
                                            guide={false}
                                            className="upper"
                                            placeholder="Estado (e.g. SP)"
                                            inputMode="text"
                                            value={this.state.state}
                                            component={MaskedField}
                                            disabled
                                        />
                                        <ErrorMessage
                                            name="state"
                                            component={InputError}
                                        />
                                    </FormSection>
                                    <h3>Dados bancários para recebimento</h3>
                                    <FormSection>
                                        <Field
                                            name="bankName"
                                            placeholder="Nome do banco"
                                            maxLength="60"
                                            inputMode="text"
                                        />
                                        <ErrorMessage
                                            name="bankName"
                                            component={InputError}
                                        />
                                        <Field
                                            name="branch"
                                            placeholder="Agência"
                                            maxLength="4"
                                            inputMode="numeric"
                                        />
                                        <ErrorMessage
                                            name="branch"
                                            component={InputError}
                                        />
                                        <Field
                                            name="accountNumber"
                                            placeholder="Número da conta"
                                            maxLength="10"
                                            inputMode="numeric"
                                        />
                                        <ErrorMessage
                                            name="accountNumber"
                                            component={InputError}
                                        />
                                    </FormSection>
                                    <h3>Informações do responsável</h3>
                                    <FormSection>
                                        <Field
                                            name="partnerName"
                                            placeholder="Nome"
                                            maxLength="60"
                                            inputMode="text"
                                        />
                                        <ErrorMessage
                                            name="partnerName"
                                            component={InputError}
                                        />
                                        <Field
                                            mask={maskCPF}
                                            guide={false}
                                            name="partnerDocument"
                                            placeholder="CPF"
                                            inputMode="numeric"
                                            component={MaskedField}
                                        />
                                        <ErrorMessage
                                            name="partnerDocument"
                                            component={InputError}
                                        />
                                        <Field
                                            mask={maskBirthdate}
                                            guide={false}
                                            name="partnerBirthdate"
                                            placeholder="Data de nascimento"
                                            inputMode="numeric"
                                            component={MaskedField}
                                        />
                                        <ErrorMessage
                                            name="partnerBirthdate"
                                            component={InputError}
                                        />
                                        <Field
                                            mask={maskPhone}
                                            guide={false}
                                            name="partnerPhone"
                                            placeholder="Telefone"
                                            inputMode="tel"
                                            component={MaskedField}
                                        />
                                        <ErrorMessage
                                            name="partnerPhone"
                                            component={InputError}
                                        />
                                    </FormSection>
                                    <h3>Email e senha para login</h3>
                                    <FormSection>
                                        <Field
                                            name="partnerEmail"
                                            placeholder="Email para login"
                                            maxLength="60"
                                            inputMode="email"
                                        />
                                        <ErrorMessage
                                            name="partnerEmail"
                                            component={InputError}
                                        />
                                        <Field
                                            type="password"
                                            name="partnerPassword"
                                            placeholder="Senha"
                                            maxLength="60"
                                            inputMode="text"
                                        />
                                        <ErrorMessage
                                            name="partnerPassword"
                                            component={InputError}
                                        />
                                    </FormSection>
                                    <VerticalSpace />
                                    <SubmitButton disabled={props.isSubmitting}>
                                        {props.isSubmitting ? (
                                            <FaSpinner
                                                color="#ffffff"
                                                size={14}
                                            />
                                        ) : (
                                            <span style={{ color: "#ffffff" }}>
                                                Efetuar cadastro
                                            </span>
                                        )}
                                    </SubmitButton>
                                </Form>
                            );
                        }}
                    </Formik>
                </Container>
            </Body>
        );
    }
}
