const fromCents = (() => {
  function splitRight(str, offset) {
    const right = str.slice(-offset);
    const left = str.substr(0, str.length - offset);

    return [left, right];
  }

  function splitThousands(num) {
    return (function _splitThousands(_num, acc = []) {
      if (!_num.length) return [].concat(acc);
      const [remaining, thousand] = splitRight(_num, 3);

      return _splitThousands(remaining, acc.concat(thousand));
    })(num).reverse();
  }

  return (cents = 0, padding = 1) => {
    const centsStr = cents.toString().padStart(2, "0");

    const [mantissa, decimal] = splitRight(centsStr, 2);
    const thousands = splitThousands(mantissa);

    return `${thousands.join(".").padStart(padding, "0")},${decimal}`;
  };
})();

const toCents = (moneyStr) => {
  const [units = "0", cents = "0"] = moneyStr.replace(/[^\d,]/g, "").split(",");

  const centsStr = cents.padEnd(2, "0");
  const unitsStr = units.padEnd(1, "0");

  return parseInt(unitsStr, 10) * 100 + parseInt(centsStr, 10);
};

export default {
  fromCents,
  toCents,
};
