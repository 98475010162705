import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Home from "./pages/Home";
import VoucherAcquire from "./pages/VoucherAcquire";
import Success from "./pages/VoucherAcquire/Success";
import VoucherRedeem from "./pages/VoucherRedeem";
import VoucherRedeemSummary from "./pages/VoucherRedeemSummary";
import VoucherRedeemConfirmation from "./pages/VoucherRedeemConfirmation";
import Establishment from "./pages/Establishment";
import NotFound from "./pages/NotFound";

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/home" component={Home} />
                <Route exact path="/campaigns" component={VoucherAcquire} />
                <Route exact path="/campaigns/success" component={Success} />
                <Route
                    exact
                    path="/campaigns/:campaign"
                    component={VoucherAcquire}
                />
                <Route
                    exact
                    path="/redeem/summary"
                    component={VoucherRedeemSummary}
                />
                <Route
                    exact
                    path="/redeem/success"
                    component={VoucherRedeemConfirmation}
                />
                <Route exact path="/redeem" component={VoucherRedeem} />
                <Route exact path="/establishment" component={Establishment} />
                <Route path="/404" component={NotFound} />
                <Redirect exact from="/" to="/home" />
                <Redirect to="/404" />
            </Switch>
        </BrowserRouter>
    );
}
