import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

const MaskedField = ({ field, ...props }) => {
    return <MaskedInput guide={false} {...field} {...props} />;
};

MaskedField.defaultProps = {
    field: {},
};

MaskedField.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    field: PropTypes.object,
};

export default MaskedField;
