const size = {
    extraSmall: "460px",
    small: "620px",
    medium: "772px",
    large: "1036px",
    extraLarge: "1681px",
};

export default {
    small: `(min-width: ${size.extraSmall})`,
    medium: `(min-width: ${size.small})`,
    large: `(min-width: ${size.medium})`,
    extraLarge: `(min-width: ${size.large})`,
};
