import React, { Component } from "react";
import PropTypes from "prop-types";
import { FaSpinner, FaChevronRight } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { Formik, ErrorMessage, Field } from "formik";
import { parseISO, format } from "date-fns";
import { ptBR } from "date-fns/locale";

import config from "../../config";
import api from "../../services/api";
import extractError from "../../lib/extract-error-from-axios";
import currency from "../../lib/currency";
import Body from "../../components/Body";
import Container from "../../components/Container";
import ActionBar from "../../components/ActionBar";
import VerticalSpace from "../../components/VerticalSpace";
import Logo from "../../components/Logo";
import Form from "../../components/Form";
import InputError from "../../components/InputError";
import SubmitButton from "../../components/SubmitButton";
import { InputButtonRight } from "../../components/InputButton";
import Button from "../../components/Button";
import LockScreen from "../../components/LockScreen";
import LockScreenModal from "../../components/LockScreenModal";
import QrCodeReader from "../../components/QrCodeReader";
import { VoucherReaderContainer } from "./styles";
import { lightToDark } from "../../lib/color";
import {
  sendRedeemFailureNotification,
  sendAlreadyRedeemedFailureNotification,
} from "./notifications";
import { sendIncorrectFormFieldsFailureNotification } from "../../tools/notifications";

import validator from "./validator";

const resetableState = {};

export default class Main extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        seller: PropTypes.string,
      }),
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        businessInfo: PropTypes.object,
      }),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  state = {
    ...resetableState,
    businessInfo: {},
    loaded: false,
    code: "",
    scannerState: false,
    scannerStateUnlocked: true,
    lockState: true,
    lockTimeout: 7200000, // 2 hours = 2 * 60 * 60 * 1000 = 7200000,
    data: {
      brandColor: "#582c8b",
      sellerId: "",
      logo: {
        location: "",
      },
    },
  };

  async componentDidMount() {
    const { location } = this.props;
    const { state } = location;
    if (state?.businessInfo) {
      this.setState({
        data: state.businessInfo,
        lockState: false,
        scannerState: true,
        loaded: true,
      });
      return;
    }
    this.setState({ businessInfo: { color: config.defaultSeller.color } });
  }

  checkPassword = async (password) => {
    const { data: apiResponse } = await api.get(
      `/v2/customer/establishments?document=${password}`,
      {
        document: password,
      }
    );
    const data = apiResponse.data[0];
    this.setState({ data });
    if (apiResponse.data.length > 0) return true;
    return false;
  };

  getLockScreenUi = (setLock) => {
    const { businessInfo } = this.state;
    return (
      <LockScreenModal
        setLock={setLock}
        checkPassword={this.checkPassword}
        title="Tela bloqueada"
        placeholder="Senha"
        errorMessage="Senha incorreta"
        observationMessage="Apenas dígitos numéricos"
        logo={businessInfo.logo}
        maxLength={14}
        style={{
          position: "fixed",
          top: "40%",
          left: "50%",
          zIndex: "10",
          alignItems: "center",
          width: "50%",
          height: "50%",
        }}
      />
    );
  };

  handleLock = (lockState) => {
    this.setState({ lockState });
  };

  handleScreenLocked = () => {
    this.setState({ scannerState: false });
  };

  handleScreenUnlocked = () => {
    const { scannerStateUnlocked } = this.state;

    this.setState({ scannerState: scannerStateUnlocked });
  };

  handleQrCodeReaderState = () => {
    const { scannerState } = this.state;
    this.setState({
      scannerState: !scannerState,
      scannerStateUnlocked: !scannerState,
    });
  };

  handleSubmit = async (values) => {
    const { history } = this.props;

    const payloadVoucher = {
      ...values,
      sellerId: this.state.data.sellerId,
      establishmentId: this.state.data.id,
    };

    try {
      const { data: voucher } = await api.post(
        "/v2/redeem/vouchers/verify-code",
        payloadVoucher
      );

      if (voucher.data.redeemedValue === voucher.data.redeemValue) {
        const amountString = `R$${currency.fromCents(
          voucher.data.redeemValue
        )}`;
        const dateString = format(
          parseISO(voucher.data.redeemedAt),
          "dd/MM/yyyy 'às' HH'h'mm",
          { locale: ptBR }
        );

        sendAlreadyRedeemedFailureNotification(amountString, dateString);
      } else {
        history.push({
          pathname: "/redeem/summary",
          state: { voucher: voucher.data, businessInfo: this.state.data },
        });
      }
    } catch (e) {
      const { error, code } = extractError(e);
      if (code === "InvalidInput") {
        sendIncorrectFormFieldsFailureNotification(
          error.extensions.invalidInput
        );
      } else {
        sendRedeemFailureNotification();
      }
    }
  };

  render() {
    const { code, scannerState, lockTimeout } = this.state;

    return (
      <Body
        primaryColor={this.state.data ? this.state.data.brandColor : "#582c8b"}
      >
        <LockScreen
          ui={this.getLockScreenUi}
          timeout={lockTimeout}
          isLocked={this.state.data ? !this.state.data.id : true}
          setIsLocked={this.handleLock}
          onScreenLocked={this.handleScreenLocked}
          onScreenUnlocked={this.handleScreenUnlocked}
        >
          <Container>
            <ActionBar>
              <Button
                type="button"
                width="30px"
                height="30px"
                backgroundColor="#fe6421"
                className="elevation"
                onClick={() => this.handleLock(true)}
              >
                <AiFillLock color="#ffffff" size={14} />
              </Button>
            </ActionBar>
            <Logo
              src={
                this.state.data
                  ? this.state.data.logo
                    ? this.state.data.logo.location
                    : ""
                  : ""
              }
            />
            <Formik
              initialValues={{
                code,
              }}
              validationSchema={validator}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <Form>
                  <VoucherReaderContainer>
                    <h3>Enquadre o QRcode abaixo</h3>
                    <QrCodeReader
                      onError={(err) => null}
                      onScan={(data) =>
                        data && props.setFieldValue("code", data)
                      }
                      state={scannerState}
                      handleChangeState={this.handleQrCodeReaderState}
                      customStyle={{
                        size: "100%",
                        minSize: "200px",
                        maxSize: "400px",
                      }}
                    />
                    <VerticalSpace />
                    <InputButtonRight
                      width="100%"
                      minWidth={200}
                      maxWidth={400}
                    >
                      <Field
                        className="upper"
                        name="code"
                        placeholder="Ou digite o código aqui"
                        maxLength="8"
                        inputMode="text"
                      />
                      <Button
                        as={SubmitButton}
                        disabled={props.isSubmitting}
                        submitting={props.isSubmitting}
                        style={{
                          backgroundColor: this.state.data
                            ? lightToDark(this.state.data.brandColor)
                            : "#582c8b",
                        }}
                      >
                        {props.isSubmitting ? (
                          <FaSpinner color="#ffffff" size={14} />
                        ) : (
                          <FaChevronRight color="#ffffff" size={14} />
                        )}
                      </Button>
                    </InputButtonRight>
                    <ErrorMessage name="code" component={InputError} />
                  </VoucherReaderContainer>
                </Form>
              )}
            </Formik>
          </Container>
        </LockScreen>
      </Body>
    );
  }
}
