import styled from "styled-components";

interface ImageUploadProps {
    [key: string]: any;
    isDragActive: boolean;
}

export default styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 30px;
    background: #ffffff;
    border-radius: var(--radius-size);
    box-shadow: var(--box-shadow);
    border: thick dashed
        ${(props: ImageUploadProps) =>
            props.isDragActive ? "#32a1ce" : "#ffffff"};
    cursor: pointer;

    img {
        height: 180px;
        object-fit: contain;
    }

    p {
        margin: 20px 0 0;
    }
`;
