import React, { useState } from "react";
import { colors } from "../../colors";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "antd";
import Cards from "react-credit-cards";
import NumberFormat from "react-number-format";
import "./cardStyle.scss";
import { cpf } from "cpf-cnpj-validator";
import { translate } from "../../config/i18n/index";
import api from "../../services/api";
import { sendDangerNotification } from "../../tools/notifications";
import { styles } from "./styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  input: {
    borderColor: "#fff !important",
    width: "75%",
    border: "none",
    color: colors.fontDisabledColor,
  },
  contentContainer: {
    margin: "4%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "60%",
  },
  mobileContentContainer: {
    margin: "4%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
  },
  title: {
    color: colors.fontRegularColor,
    fontSize: "2.8vh",
    marginBottom: "10px",
  },
  inputText: {
    fontFamily: "Poppins, sans-serif",
    textAlign: "right",
    color: colors.fontDisabledColor,
  },
}));

const submitPayment = (personal, creditCard, campaign, value, history) => {
  let requestData;
    requestData = {
      name: personal.name,
      email: personal.email,
      document: personal.document.replaceAll(".", "").replaceAll("-", ""),
      cardHolder: creditCard.holder,
      cardNumber: creditCard.number.replaceAll(" ", ""),
      cardExpirationDate: creditCard.expirationDate,
      cardSecurityCode: creditCard.cvc,
      amount: value,
      establishmentId: campaign.establishment.id,
      campaignId: campaign.id
    };

  api
    .post("/v2/customer/payments", requestData)
    .then((res) => {
      history.push({
        pathname: "/campaigns/success",
        state: {
          campaign,
          personal,
          value,
        },
      });
    })
    .catch((err) => {
      if (err.response.data.error.code === "CampaignDisabled")
        sendDangerNotification("Esta campanha não está no prazo de validade.");
      else if (err.response.data.error.code === "InvalidInput")
        sendDangerNotification("Confira os dados e tente novamente.");
      else sendDangerNotification("Transação não completada.");
    });
};

const Payment = (props) => {
  const classes = useStyles();

  const [holder, setHolder] = useState("");
  const [number, setNumber] = useState("");
  const [cvc, setCvc] = useState("");
  const [expirationDate, setExpiration] = useState("");
  const [focus, setFocus] = useState("");
  const [errorCardNumber, setErrorCardNumber] = useState(false);
  const [expirationError, setExpirationError] = useState(false);
  const [cvcError, setCvcError] = useState(false);

  const [errorCpf, setErrorCpf] = useState(false);
  const [cardDocument, setCardDocument] = useState("");

  const cvcErrorMessage = "Digite um código de segurança válido.";
  const expirationErrorMessage = "Digite uma data válida.";
  const cardNumberErrorMessage =
    "O número do cartão precisa conter de 13 a 16 caracteres.";
  const errorMessageCpf = "O CPF digitado é inválido.";

  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);

  return (
    <div
      className={
        windowSize.width < 600
          ? classes.mobileContentContainer
          : classes.contentContainer
      }
    >
      <h1 className={classes.title}>{translate("checkout.payment_title")}</h1>
      <Cards
        cvc={cvc}
        expiry={expirationDate}
        focused={focus}
        name={holder}
        number={number}
        locale={{
          valid: translate("checkout.expiration_date"),
          number: translate("checkout.card_number"),
        }}
        placeholders={{ name: translate("checkout.cardholder") }}
      />
      <form noValidate autoComplete="off">
        <TextField
          id="standard-basic"
          label={translate("checkout.cardholder")}
          style={{ border: "none", fontFamily: "Poppins, sans-serif" }}
          inputProps={{ min: 0, style: { border: "none" } }}
          className={classes.input}
          value={holder}
          onChange={(e) => {
            setHolder(e.target.value);
            setFocus("");
          }}
        />
        <NumberFormat
          id="standard-basic"
          label={translate("checkout.card_number")}
          style={{ border: "none", fontFamily: "Poppins, sans-serif" }}
          inputProps={{ min: 0, style: { border: "none" } }}
          className={classes.input}
          value={number}
          onChange={(e) => {
            setNumber(e.target.value);
            setFocus("");
            if (e.target.value.replaceAll(" ", "").length < 13)
              setErrorCardNumber(true);
            else setErrorCardNumber(false);
          }}
          customInput={TextField}
          format="#### #### #### ####"
          helperText={errorCardNumber ? cardNumberErrorMessage : false}
          error={errorCardNumber}
        />
        <NumberFormat
          id="standard-basic"
          label={translate("checkout.document")}
          style={{ border: "none", fontFamily: "Poppins, sans-serif" }}
          inputProps={{ min: 0, style: { border: "none" } }}
          className={classes.input}
          value={cardDocument}
          onChange={(e) => {
            setCardDocument(e.target.value);
            if (!cpf.isValid(e.target.value)) setErrorCpf(true);
            else setErrorCpf(false);
          }}
          customInput={TextField}
          format="###.###.###-##"
          error={errorCpf}
          helperText={errorMessageCpf && errorCpf ? errorMessageCpf : false}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: "12.5%",
            marginRight: "12.5%",
          }}
        >
          <NumberFormat
            id="standard-basic"
            label={translate("checkout.expiration_date")}
            style={{ border: "none", fontFamily: "Poppins, sans-serif" }}
            inputProps={{ min: 0, style: { border: "none" } }}
            value={expirationDate}
            onChange={(e) => {
              setExpiration(e.target.value);
              setFocus("");
              if (e.target.value.replaceAll(" ", "").length < 5) {
                setExpirationError(true);
              } else {
                setExpirationError(false);
              }
            }}
            format="##/##"
            customInput={TextField}
            error={expirationError}
            helperText={expirationError ? expirationErrorMessage : false}
          />
          <NumberFormat
            id="standard-basic"
            label={translate("checkout.cvv")}
            style={{ border: "none", fontFamily: "Poppins, sans-serif" }}
            inputProps={{ min: 0, style: { border: "none" } }}
            value={cvc}
            onChange={(e) => {
              setCvc(e.target.value);
              setFocus("cvc");
              if (e.target.value.replaceAll(" ", "").length < 3) {
                setCvcError(true);
              } else {
                setCvcError(false);
              }
            }}
            customInput={TextField}
            format="####"
            error={cvcError}
            helperText={cvcError ? cvcErrorMessage : false}
          />
        </div>
      </form>
      <Button
        style={styles.button}
        onClick={() => {
          if (
            !holder ||
            !number ||
            !expirationDate ||
            !cvc ||
            errorCardNumber ||
            cvcError ||
            expirationError
          )
            sendDangerNotification(
              "Você precisa preencher todas as informações corretamente."
            );
          else {
            submitPayment(
              props.personalData,
              { holder, number, expirationDate, cvc },
              props.campaign,
              props.value,
              props.history
            );
          }
        }}
      >
        {translate("checkout.confirm_transaction_button")}
      </Button>
    </div>
  );
};

export default Payment;
