import { sendDangerNotification } from "../../tools/notifications";

export const sendAlreadyRedeemedFailureNotification = (amount, date) => {
    return sendDangerNotification(
        `Voucher no valor de ${amount} já foi resgatado na data de ${date}.`
    );
};

export const sendRedeemFailureNotification = () => {
    return sendDangerNotification("Voucher inexistente.");
};
