import styled from "styled-components";
import { color, space, layout } from "styled-system";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-size);
  box-shadow: var(--box-shadow);
  transition: all 0.3s;

  ${layout};
  ${color};
  ${space};
`;

Card.defaultProps = {
  backgroundColor: "#fbf9fe",
  color: "var(--font-color)",
  padding: "66px",
  width: "100%",
  height: "100%",
};

export default Card;
