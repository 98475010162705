import styled from "styled-components";
import { colors } from "../../../colors";

export const styles = {
  button: {
    backgroundColor: colors.buttonPrimaryColor,
    border: "none",
    color: colors.purpleColor,
    fontWeight: "bold",
    fontFamily: "Poppins, sans-serif",
    width: "110%",
    height: "70px",
    fontSize: "1.5vh",
  },
};

export const HeaderCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: transparent;
  padding: ;
  width: 90%;
  height: 140px;
  border-radius: 5px;

  img {
    width: 150px;
    margin-left: 0;
    margin-bottom: 0;
  }
`;
