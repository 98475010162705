import styled from "styled-components";
import { layout } from "styled-system";
import PropTypes from "prop-types";

import parseLengthProp, { lengthProp } from "../../lib/parse-length-prop";

interface InputButtonProps {
    [key: string]: any;
}

export const InputButtonRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${layout};

    button {
        width: ${(props: InputButtonProps) =>
            parseLengthProp(props.buttonWidth)};
        height: ${(props: InputButtonProps) =>
            parseLengthProp(props.buttonHeight)};
        border-radius: 0 var(--radius-size) var(--radius-size) 0;
    }

    &&& input {
        width: ${(props: InputButtonProps) =>
            parseLengthProp(props.inputWidth)};
        height: ${(props: InputButtonProps) =>
            parseLengthProp(props.inputHeight)};
        border-radius: var(--radius-size) 0 0 var(--radius-size);
    }
`;

InputButtonRight.propTypes = {
    buttonWidth: PropTypes.oneOfType([...lengthProp]),
    buttonHeight: PropTypes.oneOfType([...lengthProp]),
    inputWidth: PropTypes.oneOfType([...lengthProp]),
    inputHeight: PropTypes.oneOfType([...lengthProp]),
} as InputButtonProps;

InputButtonRight.defaultProps = {
    width: "100%",
    buttonWidth: "60px",
    buttonHeight: "var(--input-size)",
    inputWidth: "100%",
    inputHeight: "var(--input-size)",
} as InputButtonProps;
