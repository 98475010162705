import styled from "styled-components";
import { Form } from "formik";

export default styled(Form)`
    display: flex;
    flex-direction: column;

    p {
        margin: 40px 0 20px;
    }

    input.upper {
        text-transform: uppercase;
    }
    input.upper::placeholder {
        text-transform: none;
    }

    span.observation {
        margin-left: 16px;
        margin-top: 4px;
        margin-bottom: 4px;
        color: var(--font-color);
        font-size: 0.8rem;
        font-style: italic;
    }
    span.observation:before {
        content: "* ";
    }
`;
