import yup from "../../tools/yup";

export default yup.object().shape({
    brandColor: yup
        .string()
        .trim()
        .lowercase()
        .matches(/^(#[0-9a-f]{6})?$/, "Esse campo deve ter o formato #582c8b"),
    fancyName: yup
        .string()
        .trim()
        .max(60, "Esse campo deve conter no máximo 60 caracteres"),
    website: yup
        .string()
        .trim()
        .max(200, "Esse campo deve conter no máximo 200 caracteres"),
    bonus: yup
        .string()
        .trim()
        .matches(
            /^\d+([.,]\d{1,2})?%?$/,
            "Esse campo deve conter uma porcentagem"
        )
        .max(7, "O valor informado é muito alto"),
    name: yup
        .string()
        .trim()
        .max(60, "Esse campo deve conter no máximo 60 caracteres")
        .matches(
            /^[0-9a-zA-Z\u00C0-\u024F\u1E00-\u1EFF '.-]+$/,
            "Esse campo contém caracteres inválidos"
        ),
    document: yup.string().trim().cnpj("CNPJ inválido"),

    line2: yup
        .string()
        .trim()
        //.required('Esse campo é obrigatório')
        .max(60, "Esse campo deve conter no máximo 60 caracteres"),
    line3: yup
        .string()
        .trim()
        .max(60, "Esse campo deve conter no máximo 60 caracteres"),
    bankName: yup
        .string()
        .trim()
        .required("Esse campo é obrigatório")
        .max(30, "Esse campo deve conter no máximo 30 caracteres"),
    branch: yup
        .string()
        .trim()
        .required("Esse campo é obrigatório")
        .max(12, "Esse campo deve conter no máximo 12 caracteres")
        .matches(/^\d+$/, "Esse campo contém caracteres inválidos"),
    accountNumber: yup
        .string()
        .trim()
        .required("Esse campo é obrigatório")
        .max(12, "Esse campo deve conter no máximo 12 caracteres")
        .matches(/^[0-9.-]*$/, "Esse campo contém caracteres inválidos"),
    partnerName: yup
        .string()
        .trim()
        .required("Esse campo é obrigatório")
        .max(60, "Esse campo deve conter no máximo 60 caracteres")
        .matches(
            /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF '.-]+$/,
            "Esse campo contém caracteres inválidos"
        ),
    partnerDocument: yup
        .string()
        .trim()
        .required("Esse campo é obrigatório")
        .cpf("CPF inválido"),
    agencyDocument: yup.string().trim().cpf("CPF inválido"),
    partnerBirthdate: yup
        .string()
        .trim()
        .required("Esse campo é obrigatório")
        .matches(
            /^\d{2}\/\d{2}\/\d{4}$/,
            "Esse campo deve seguir o formato dd/mm/aaaa"
        )
        .isDate("Data inválida"),
    partnerPhone: yup
        .string()
        .trim()
        .required("Esse campo é obrigatório")
        .min(13, "Esse campo deve conter no mínimo 10 dígitos")
        .max(33, "Esse campo deve conter no máximo 30 dígitos")
        .matches(/^[0-9(). +-]*$/, "Esse campo contém caracteres inválidos"),
    partnerEmail: yup
        .string()
        .trim()
        .lowercase()
        .required("Esse campo é obrigatório")
        .max(60, "Esse campo deve conter no máximo 60 caracteres")
        .email("Email inválido"),
    partnerPassword: yup
        .string()
        .trim()
        .required("Esse campo é obrigatório")
        .min(8, "Esse campo deve conter no mínimo 8 caracteres")
        .max(60, "Esse campo deve conter no máximo 60 caracteres")
        .matches(
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
            "A senha deve conter uma letra maiúscula, uma minúscula e um número"
        ),
});
