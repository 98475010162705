export const styles = {
    img: {
        width: "80%",
        marginLeft: "15%",
        marginTop: "-10%",
    } as unknown as React.CSSProperties,
    badge: {
        width: "8%",
        right: "55%",
        marginTop: "22.6%",
        position: "absolute",
    } as unknown as React.CSSProperties,
    badgeApp: {
        width: "10%",
        right: "63%",
        marginTop: "22%",
        position: "absolute"
    } as unknown as React.CSSProperties
};
