import moment from "moment";

export default (value) => {
  if (!value) {
    return true;
  }

  const [day, rawMonth, year] = value.split("/");
  const month = rawMonth - 1;

  return moment([year, month, day]).isValid();
};
