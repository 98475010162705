export const maskCPF = Array(14).fill(/\d/);
maskCPF[3] = ".";
maskCPF[7] = ".";
maskCPF[11] = "-";

export const maskCNPJ = Array(18).fill(/\d/);
maskCNPJ[2] = ".";
maskCNPJ[6] = ".";
maskCNPJ[10] = "/";
maskCNPJ[15] = "-";

export const maskCEP = Array(9).fill(/\d/);
maskCEP[5] = "-";

export const maskStateAbbreviation = Array(2).fill(/[a-zA-Z]/);

export const maskBirthdate = Array(10).fill(/\d/);
maskBirthdate[2] = "/";
maskBirthdate[5] = "/";

export const maskPhone = Array(14).fill(/\d/);
maskPhone[0] = "(";
maskPhone[3] = ")";
maskPhone[4] = " ";

export const maskCardNumber = Array(23).fill(/\d/);
maskCardNumber[4] = " ";
maskCardNumber[9] = " ";
maskCardNumber[14] = " ";
maskCardNumber[19] = " ";

export const maskCardExpiry = Array(5).fill(/\d/);
maskCardExpiry[2] = "/";

export const maskCardCVC = Array(4).fill(/\d/);
