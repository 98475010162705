export default {
  translations: {
    home: {
      create_establishment: "criar estabelecimento",
      check_our_establishments:
        "Confira os estabelecimentos da nossa rede credenciada",
      rights_statement: "Todos os direitos reservados.",
      use_terms: "Termos de Uso e Política de Privacidade.",
    },
    checkout: {
      back: "Voltar",

      // Steps Title
      confirmation_title: "Compra de campanha",
      identification_title: "Dados para identificação",
      payment_title: "Dados do Cartão de Crédito",
      value_confirmation: "confirme o valor",

      // Footer Steps
      step1: "confirme o valor",
      step2: "identificação",
      step3: "cartão de crédito",

      // Confirmation Step
      fee_disclaimer: "acrescido do valor de ",
      fee_disclaimer_tax: " de taxas",
      use_terms: "Termos de Uso e Política de Privacidade.",

      // Identification Step
      name: "Nome",
      email: "Email",
      document: "CPF",
      phone: "Telefone",

      // Payment Step
      card_number: "Número do cartão",
      cardholder: "Nome do Titular",
      expiration_date: "Data de validade",
      cvv: "Código",

      // Buttons title
      confirm_button: "confirmar",
      confirm_transaction_button: "confirmar transação",
    },
    registration: {
      // Fields
      trade_name: "Nome fantasia ou apelido",
      accent_color: "Cor de destaque (ex. #582c8b)",
      description: "Descrição",
      web_site: "Web site",
      document: "CNPJ",
      contact_email: "E-mail de contato",
      email: "E-mail",
      contact_phone: "Telefone de contato",
      phone: "Telefone",
      abreviated_phone: "Tel",
      company_name: "Razão social",
      postal_code: "CEP",
      state: "Estado",
      city: "Cidade",
      neighborhood: "Bairro",
      eg_line1: "Logradouro (e.g Rua. Avenida)",
      line1: "Logradouro",
      line2: "Número",
      line3: "Complemento",
      full_name: "Nome completo",
      partner_full_name: "Nome completo do sócio",
      partner_document: "CPF",
      partner_birthdate: "Data de nascimento",
      bank_name: "Nome do Banco",
      bank_branch: "Agência",
      account_number: "Conta corrente",
      password: "Senha",
    },
    campaign: {
      get: "Consuma: ",
      expiration: "Validade: ",
      pay: "Pague apenas: ",
      min: "Mínimo: ",
      max: "Máximo: ",
      convenience_fee: "Taxa de conveniência: ",
      partial_redeem: "Resgate parcial",
      one_time_redeem: "Resgate único",
      details: "Ver detalhes",
    },
    not_found: {
      title: "Não encontramos nada aqui",
      description:
        "Desculpe, mas não foi possível completar a sua pesquisa. Tente fazer uma nova busca.",
      home: "voltar à home",
    },
  },
};
