export default {
  translations: {
    home: {
      create_establishment: "register your business",
      check_our_establishments: "Check out our featured partners",
      rights_statement: "All rights reserved.",
      use_terms: "Terms of Service and privacy policy.",
    },
    checkout: {
      back: "Back",

      // Steps Title
      confirmation_title: "Campaign purchase",
      identification_title: "Personal info",
      payment_title: "Credit Card info",

      // Footer Steps
      step1: "confirm amount",
      step2: "identification",
      step3: "credit card",

      // Confirmation Step
      fee_disclaimer: "convenience fee applied: ",
      fee_disclaimer_tax: "",
      use_terms: "Terms of Service and privacy policy",
      value_confirmation: "confirm amount",

      // Identification Step
      name: "Name",
      email: "Email",
      document: "Document",
      phone: "Phone Number",

      // Payment Step
      card_number: "Card Number",
      cardholder: "Cardholder",
      expiration_date: "Exp. Date",
      cvv: "Security Code",

      // Buttons title
      confirm_button: "confirm",
      confirm_transaction_button: "confirm purchase",
    },
    registration: {
      // Fields
      trade_name: "Trade name",
      accent_color: "Accent color (e.g. #582c8b)",
      description: "Description",
      web_site: "Web site",
      document: "Document",
      contact_email: "Contact e-mail",
      email: "E-mail",
      contact_phone: "Contact phone",
      phone: "Phone",
      abreviated_phone: "Phone",
      company_name: "Company name",
      postal_code: "Postal code",
      state: "State",
      city: "City",
      neighborhood: "Neighborhood",
      eg_line1: "Line 1 (e.g Rua. Avenida)",
      line1: "Line 1",
      line2: "Line 2",
      line3: "Line 3",
      full_name: "Full name",
      partner_full_name: "Full name",
      partner_document: "Document",
      partner_birthdate: "Birthdate",
      bank_name: "Bank",
      bank_branch: "Branch",
      account_number: "Account number",
      password: "Password",
    },
    campaign: {
      get: "Get:",
      expiration: "Expiration date: ",
      pay: "Pay: ",
      min: "Minimun amount: ",
      max: "Maximum amount: ",
      convenience_fee: "Convenience fee: ",
      partial_redeem: "Resgate parcial",
      one_time_redeem: "Resgate único",
      details: "Details",
    },
    not_found: {
      title: "We can't seem to find the page you are looking for.",
      description:
        "Sorry for the inconvenience. Please, check the addresss and try again.",
      home: "back to home",
    },
  },
};
