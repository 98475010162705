import styled, { keyframes, css } from "styled-components";
import PropTypes from "prop-types";
import { colors } from "../../colors";
import Button from "../Button";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SubmitButton = styled(Button).attrs(() => ({
    type: "submit",
    backgroundColor: colors.purpleColor,
}))`
    &[disabled] {
        cursor: not-allowed;
        opacity: 0.6;

        ${(props) =>
            props.submitting &&
            css`
                svg {
                    animation: ${rotate} 2s linear infinite;
                }
            `}
    }
`;

SubmitButton.propTypes = {
    disabled: PropTypes.bool,
    submitting: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};

export default SubmitButton;
