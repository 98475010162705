import styled from "styled-components";
import device from "../../styles/device";

export default styled.img`
    display: ${(props) => (props.src ? "block" : "none")};
    margin: 0 auto;
    max-width: 320px;
    height: 100px;
    object-fit: contain;
    transition: all 0.3s;
    position: relative;
    @media ${device.small} {
        max-width: 360px;
    }
    @media ${device.medium} {
        max-width: 500px;
    }
    @media ${device.large} {
        max-width: 600px;
    }
`;
