import { store } from "react-notifications-component";

export const sendNotification = (type, message, { title, duration = 5000 }) => {
    const dangerStructure = {
        type,
        title: title || `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
        message,
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration,
            onScreen: true,
            pauseOnHover: true,
        },
    };
    store.addNotification(dangerStructure);
};

// Notification per type

export const sendSuccessNotification = (
    message,
    { title = "Sucesso", duration = 5000 } = {}
) => {
    sendNotification("success", message, { title, duration });
};

export const sendDangerNotification = (
    message,
    { title = "Falha", duration = 5000 } = {}
) => {
    sendNotification("danger", message, { title, duration });
};

export const sendWarningNotification = (
    message,
    { title = "Alerta", duration = 5000 } = {}
) => {
    sendNotification("warning", message, { title, duration });
};

export const sendInfoNotification = (
    message,
    { title = "Informação", duration = 5000 } = {}
) => {
    sendNotification("info", message, { title, duration });
};

export const sendDefaultNotification = (
    message,
    { title = "Notificação", duration = 5000 } = {}
) => {
    sendNotification("default", message, { title, duration });
};

// Global notifications

export const sendGenericFailureNotification = (
    email,
    message = "Unexpected error"
) => {
    return sendDangerNotification(
        `Ocorreu uma falha inesperada, se necessário entre em contato com ${email} e informe o erro: ${message}`
    );
};

export const sendGetBusinessInfoFailureNotification = (
    email,
    message = "Unexpected error"
) => {
    return sendDangerNotification(
        `Ocorreu uma falha inesperada ao buscar informações do estabelecimento, se necessário entre em contato com ${email} e informe o erro: ${message}`
    );
};

export const sendIncorrectFormFieldsFailureNotification = (fields) => {
    const fieldsString = Object.keys(fields).join(", ");

    if (fieldsString === "partnerPhone") {
        return sendDangerNotification("O telefone digitado é inválido.");
    } else
        return sendDangerNotification(
            `Os seguintes campos estão incorretos: ${fieldsString}`
        );
};
