import styled from "styled-components";
import { colors } from "../../colors";
import device from "../../styles/device";

export const styles = {
    root: {
        display: "flex",
        flexDirection: "column",
        padding: "2%",
        fontFamily: "Poppins, sans-serif",
        color: colors.darkPurple,
        border: "none",
        alignItems: "center",
        justifyContent: "center",
    } as unknown as React.CSSProperties,
    content: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
    } as unknown as React.CSSProperties,
    header: {
        width: "100%",
        alignText: "left",
    },
    logoSuccess: {
        marginTop: "5%",
        width: "10%",
    },
    logo: {
        width: "20vh",
    },
    footer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "space-between",
        fontFamily: "Poppins, sans-serif",
        color: colors.lightPurple,
        fontSize: "1.0vw",
        position: "fixed",
        bottom: 30,
        textDecoration: "underline",
        textJustify: "inter-word",
        width: "70%",
        textAlign: "center",
        display: "flex",
        marginLeft: "15%",
    } as unknown as React.CSSProperties,
    outerDiv: {},
    campaignTitle: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 20,
        fontWeight: "1000",
        marginTop: "4%",
    },
    campaignSubtitle: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 16,
        fontWeight: "800",
        color: colors.fontDisabledColor,
        marginBottom: "4%",
    },
    campaignName: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 18,
        fontWeight: "1000",
        color: colors.fontRegularColor,
        marginLeft: "5%",
        width: "45%",
    } as unknown as unknown as React.CSSProperties,
    campaignValue: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 18,
        fontWeight: 1000,
        color: colors.lightPurple,
    },
    campaignExpiringDate: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontDisabledColor,
        fontSize: 12,
        fontWeight: 500,
    },
    campaignType: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontDisabledColor,
        fontSize: 12,
        fontWeight: 500,
    },
    convenienceFee: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontDisabledColor,
        fontSize: 12,
        fontWeight: 500,
    },
    detailText: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontDisabledColor,
        fontWeight: 700,
        textAlign: "right",
        fontSize: 16,
        style: "normal",
    } as unknown as React.CSSProperties,
    button: {
        backgroundColor: colors.lightPurple,
        border: "none",
        color: "#fff",
        fontWeight: "bold",
        fontFamily: "Poppins, sans-serif",
        width: "65%",
        height: "6vh",
        fontSize: "2vh",
        marginTop: "4%",
        marginBottom: "4%",
    } as unknown as React.CSSProperties,
    successButton: {
        backgroundColor: colors.lightPurple,
        border: "none",
        color: "#fff",
        fontWeight: "bold",
        fontFamily: "Poppins, sans-serif",
        width: "20%",
        height: "4.5vw",
        fontSize: "1.0vw",
        marginTop: "4%",
        marginBottom: "4%",
    } as unknown as React.CSSProperties,
    successTitle: {
        fontWeight: 400,
        fontSize: "3vh",
        marginTop: "2%",
    },
    boldTitle: {
        fontWeight: "700",
    } as unknown as React.CSSProperties,
    transactionDescription: {
        marginTop: "2%",
        fontSize: "2.4vh",
        color: colors.fontRegularColor,
        width: "50%",
        textAlign: "center",
    } as unknown as React.CSSProperties,
};

export const Header = styled.header`
    a {
        display: block;
        margin: 8px 0;
    }
`;

export const CurrencyHighlight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    &.currencyHighlight div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: var(--font-lighter-color);
        transition: all 0.3s;

        :focus-within {
            color: var(--font-color);
        }

        span {
            font-size: 2rem;
        }

        input {
            text-align: right;
            width: 85%;
            height: auto;
            border: 0;
            border-bottom: 1px solid var(--font-lighter-color);
            border-radius: 0;
            padding: 0;
            font-size: 3rem;
            cursor: pointer;
            transition: all 0.3s;

            ::placeholder {
                opacity: 1;
                color: var(--font-lighter-color);
            }

            :focus,
            :focus::placeholder {
                color: var(--font-color);
                cursor: pointer;
            }

            :disabled {
                background-color: transparent;
                cursor: default;
                border-bottom: 0;
            }
        }

        @media ${device.small} {
            width: 95%;
            input {
                font-size: 3.5rem;
            }
        }

        @media ${device.medium} {
            width: 85%;
            input {
                font-size: 4rem;
            }
        }

        @media ${device.large} {
            width: 75%;
            input {
                font-size: 5rem;
            }
        }
    }
`;

export const CreditCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    transition: all 0.3s;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    div.creditCardInputs {
        flex: 1;
        margin-top: 30px;

        input {
            margin-top: 15px;
        }
        input:first-child {
            margin-top: 0;
        }
    }

    @media ${device.large} {
        flex-direction: row;

        div.creditCardInputs {
            margin-top: 0;
            margin-left: 15px;
        }
    }
`;
