import React, { useEffect, useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import api from "../../services/api";
import { styles } from "./styles";
import { Card, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { colors } from "../../colors";
import Confirmation from "./Confirmation";
import Identification from "./Identification";
import Payment from "./Payment";
import { translate } from "../../config/i18n/index";

interface campaign {
    [key: string]: any;
}

const VoucherAcquire = (props) => {
    const [stage, setStage] = useState(1);
    const [campaign, setCampaign] = useState<campaign>({});
    const [loading, setLoading] = useState(true);
    const [errorMessage] = useState(null);
    const [personalData, setPersonalData] = useState(null);
    const [valueStore, setValueStore] = useState(null);

    const [windowSize, setWindowSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const campaignId = props.match.params.campaign;

    var date, day, month, year, fixedValue, promotionalValue;

    if (campaign) {
        date = new Date(
            campaign.validDate ? campaign.validDate : campaign.endAt
        );
        day = date.getDate() + 1;
        month = date.getMonth() + 1;
        year = date.getFullYear();
        fixedValue =
            campaign.limitMaxValue === campaign.limitMinValue ? true : false;
        promotionalValue = fixedValue
            ? ((campaign.limitMaxValue / 100) * (campaign.bonus / 100 + 1))
                .toFixed(2)
                .toString()
                .replace(".", ",")
            : (campaign.limitMaxValue / 100)
                .toFixed(2)
                .toString()
                .replace(".", ",");
    }

    const antIcon = (
        <LoadingOutlined
            style={{ fontSize: 24, color: colors.purpleColor }}
            spin
        />
    );

    useEffect(() => {
        api.get(`/v2/customer/campaigns/${campaignId}`)
            .then((res) => {
                setCampaign(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                props.history.push("/404");
            });
    }, []);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        });
    }, []);

    return (
        <div style={styles.outerDiv}>
            <div style={styles.root}>
                <div style={styles.header}>
                    <a
                        onClick={() => {
                            if (stage === 1) {
                                props.history.push("/home");
                            } else if (stage === 2) {
                                setStage(1);
                            } else if (stage === 3) {
                                setStage(2);
                            }
                        }}
                    >
                        ← {translate("checkout.back")}
                    </a>
                </div>
                {loading ? (
                    <div style={styles.content}>
                        <Spin indicator={antIcon} />
                    </div>
                ) : (
                    <div style={styles.content}>
                        {campaign.establishment.logo ? (
                            <img
                                src={campaign.establishment.logo.location}
                                style={styles.logo}
                            />
                        ) : (
                            <h1>
                                Estabelecimento:{" "}
                                {campaign.establishment.fancyName
                                    ? campaign.establishment.fancyName
                                    : campaign.establishment.name
                                        ? campaign.establishment.name
                                        : campaign.establishment.parnerName
                                            ? campaign.establishment.partnerName
                                            : ""}
                            </h1>
                        )}

                        <Card
                            hoverable
                            style={{
                                borderRadius: 10,
                                width: windowSize.width > 600 ? "40%" : "95%",
                                marginTop: "3%",
                                backgroundColor: colors.gray,
                            }}
                            onClick={() => {
                                props.history.push("/campaigns/" + campaign.id);
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                {campaign.limitMaxValue && fixedValue ? (
                                    <div
                                        style={{
                                            backgroundColor: colors.blue,
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: 10,
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: 100,
                                                color: "#fff",
                                                opacity: "0.2",
                                                fontFamily:
                                                    "Bebas Neue, cursive",
                                                marginLeft: 10,
                                                position: "absolute",
                                                bottom: 10,
                                            }}
                                        >
                                            {campaign.bonus}
                                        </span>
                                        {campaign.bonus >= 100 ? (
                                            <span
                                                style={{
                                                    fontSize: 50,
                                                    color: "#fff",
                                                    fontFamily:
                                                        "Bebas Neue, cursive",
                                                    top: 46,
                                                    position: "relative",
                                                    left: 5,
                                                }}
                                            >
                                                {campaign.bonus
                                                    ? parseInt(campaign.bonus)
                                                    : ""}
                                                %
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    fontSize: 62,
                                                    color: "#fff",
                                                    fontFamily:
                                                        "Bebas Neue, cursive",
                                                    top: 33,
                                                    position: "relative",
                                                    left: 17,
                                                }}
                                            >
                                                {campaign.bonus
                                                    ? parseInt(campaign.bonus)
                                                    : ""}
                                                %
                                            </span>
                                        )}
                                    </div>
                                ) : campaign.voucher ? (
                                    <div
                                        style={{
                                            backgroundColor: colors.lightPurple,
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: 10,
                                        }}
                                    >
                                        {campaign.bonus >= 100 ? (
                                            <span
                                                style={{
                                                    fontSize: 100,
                                                    color: "#fff",
                                                    opacity: "0.2",
                                                    fontFamily:
                                                        "Bebas Neue, cursive",
                                                    position: "absolute",
                                                }}
                                            >
                                                {campaign.bonus}
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    fontSize: 100,
                                                    color: "#fff",
                                                    opacity: "0.2",
                                                    fontFamily:
                                                        "Bebas Neue, cursive",
                                                    marginLeft: 10,
                                                    position: "absolute",
                                                    bottom: 10,
                                                }}
                                            >
                                                {campaign.bonus}
                                            </span>
                                        )}
                                        {campaign.bonus >= 100 ? (
                                            <span
                                                style={{
                                                    fontSize: 50,
                                                    color: "#fff",
                                                    fontFamily:
                                                        "Bebas Neue, cursive",
                                                    top: 46,
                                                    position: "relative",
                                                    left: 5,
                                                }}
                                            >
                                                {campaign.bonus
                                                    ? parseInt(campaign.bonus)
                                                    : ""}
                                                %
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    fontSize: 62,
                                                    color: "#fff",
                                                    fontFamily:
                                                        "Bebas Neue, cursive",
                                                    top: 33,
                                                    position: "relative",
                                                    left: 17,
                                                }}
                                            >
                                                {campaign.bonus
                                                    ? parseInt(campaign.bonus)
                                                    : ""}
                                                %
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            backgroundColor: colors.lightGreen,
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: 10,
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: 100,
                                                color: "#fff",
                                                opacity: "0.2",
                                                fontFamily:
                                                    "Bebas Neue, cursive",
                                                marginLeft: 10,
                                                position: "absolute",
                                                bottom: 10,
                                            }}
                                        >
                                            DOAÇÃO
                                        </span>
                                        <span
                                            style={{
                                                fontSize: 50,
                                                color: "#fff",
                                                fontFamily:
                                                    "Bebas Neue, cursive",
                                                position: "relative",
                                                width: "15%",
                                                lineHeight: 1,
                                                left: -2,
                                            }}
                                        >
                                            DOA- ÇÃO
                                        </span>
                                    </div>
                                )}

                                <div style={styles.campaignName}>
                                    <span>{campaign.title} </span>
                                    {campaign.validDate ? (
                                        <p style={styles.campaignExpiringDate}>
                                            <span style={{ fontWeight: 700 }}>
                                                {translate(
                                                    "campaign.expiration"
                                                )}
                                            </span>{" "}
                                            {day + "/" + month + "/" + year}
                                        </p>
                                    ) : null}
                                    {fixedValue && campaign.limitMaxValue ? (
                                        <p style={styles.campaignExpiringDate}>
                                            <span style={{ fontWeight: 700 }}>
                                                {translate("campaign.get")}
                                            </span>{" "}
                                            {"R$: " + promotionalValue}
                                        </p>
                                    ) : null}
                                    {fixedValue && campaign.limitMaxValue ? (
                                        <p style={styles.campaignExpiringDate}>
                                            <span style={{ fontWeight: 700 }}>
                                                {translate("campaign.pay")}
                                            </span>
                                            {"R$: " +
                                                (campaign.limitMaxValue / 100)
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(".", ",")}{" "}
                                        </p>
                                    ) : null}
                                    {!fixedValue && campaign.limitMinValue ? (
                                        <p style={styles.campaignExpiringDate}>
                                            <span style={{ fontWeight: 700 }}>
                                                {translate("campaign.min")}
                                            </span>
                                            {"R$: " +
                                                (campaign.limitMinValue / 100)
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(".", ",")}{" "}
                                        </p>
                                    ) : null}
                                    {!fixedValue && campaign.limitMaxValue ? (
                                        <p style={styles.campaignExpiringDate}>
                                            <span style={{ fontWeight: 700 }}>
                                                {translate("campaign.max")}
                                            </span>
                                            {"R$: " +
                                                (campaign.limitMaxValue / 100)
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(".", ",")}{" "}
                                        </p>
                                    ) : null}
                                    <p style={styles.campaignType}>
                                        {campaign.oneTimeRedeem
                                            ? translate(
                                                "campaign.one_time_redeem"
                                            )
                                            : campaign.partialRedeem
                                                ? translate(
                                                    "campaign.partial_redeem"
                                                )
                                                : ""}
                                    </p>
                                    <p style={styles.convenienceFee}>
                                        <span style={{ fontWeight: 700 }}>
                                            {translate(
                                                "campaign.convenience_fee"
                                            )}
                                        </span>
                                        {campaign.convenienceFee
                                            ? campaign.convenienceFee
                                                .toString()
                                                .replace(".", ",") + "%"
                                            : "0%"}
                                    </p>
                                </div>
                                <div
                                    style={{ width: "15%", textAlign: "right" }}
                                ></div>
                            </div>
                        </Card>

                        {stage === 1 && !errorMessage ? (
                            <Confirmation
                                setStage={() => setStage(2)}
                                campaign={campaign}
                                storeValue={(value) => setValueStore(value)}
                                fixedValue={
                                    campaign.limitMaxValue ===
                                        campaign.limitMinValue
                                        ? true
                                        : false
                                }
                            />
                        ) : stage === 2 && !errorMessage ? (
                            <Identification
                                setStage={() => setStage(3)}
                                campaign={campaign}
                                storePersonalData={(data) =>
                                    setPersonalData(data)
                                }
                                value={valueStore}
                            />
                        ) : stage === 3 && !errorMessage ? (
                            <Payment
                                campaign={campaign}
                                value={valueStore}
                                personalData={personalData}
                                history={props.history}
                            />
                        ) : (
                            <h4>{errorMessage}</h4>
                        )}
                    </div>
                )}
            </div>
            {windowSize.width < 600 ? null : (
                <>
                    <div style={styles.footer}>
                        <div>
                            <span>{translate("checkout.step1")}</span>
                        </div>
                        <div>
                            <span>{translate("checkout.step2")}</span>
                        </div>
                        <div>
                            <span>{translate("checkout.step3")}</span>
                        </div>
                    </div>
                    <div
                        style={{
                            borderBottom: "5px solid " + colors.lightPurple,
                            width:
                                stage === 1
                                    ? "0%"
                                    : stage === 2
                                        ? "33%"
                                        : stage === 3
                                            ? "66%"
                                            : "100%",
                            borderTop: "0px",
                            position: "fixed",
                            bottom: "0px",
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default VoucherAcquire;
