import styled from "styled-components";

import { lightToDark } from "../../lib/color";

interface BodyProps {
    primaryColor: string;
}

export default styled.div`
    --primary-color: #4a0474;
    --primary-dark-color: ${(props: BodyProps) =>
        lightToDark(props.primaryColor)};

    display: flex;
    flex-direction: column;
    background: var(--primary-color);
    min-height: 100vh;

    .text-center {
        text-align: center;
    }
    .align-center {
        align-items: center;
    }
    .justify-center {
        justify-content: center;
    }

    .elevation {
        box-shadow: var(--box-shadow);
    }
`;
