import styled from "styled-components";
import Card from "../Card";

const LockScreenModalStyle = styled(Card).attrs(() => ({
    width: [300, 320, 360, 400],
}))`
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    align-items: center;
    transition: all 0.3s;

    img {
        margin: 0 auto 30px;
        max-width: 80%;
        height: 100px;
        object-fit: contain;
    }

    h3 {
        margin: 10px 0 40px;
    }
`;

export default LockScreenModalStyle;
