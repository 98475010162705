import styled from "styled-components";
import device from "../../styles/device";

export const Section = styled.div`
    display: flex;
    flex-direction: column;

    li {
        line-height: 1.8rem;
    }

    strong {
        font-weight: 600;
    }

    .currencyHighlight {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 3rem;
        line-height: 3rem;
        height: auto;
        font-weight: 300;
        transition: all 0.3s;

        span {
            font-size: 2rem;
            margin-right: 10px;
        }

        @media ${device.small} {
            font-size: 3.5rem;
            line-height: 3.5rem;
        }

        @media ${device.medium} {
            font-size: 4rem;
            line-height: 4rem;
        }
    }
`;
