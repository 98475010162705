import React from "react";
import { Button } from "antd";
import { styles } from "./styles";
import { translate } from "../../config/i18n";

const NotFound = (props) => {
    return (
        <div>
            <div style={styles.root}>
                <div style={styles.header}>
                    <a onClick={() => props.history.push("/home")}>
                        ← {translate("checkout.back")}
                    </a>
                </div>
                <div style={styles.content}>
                    <img
                        src={require("../../assets/404.png")}
                        alt="404"
                        style={styles.logoSuccess}
                    ></img>

                    <span style={styles.boldTitle}>Ooops!</span>
                    <span style={styles.successTitle}>
                        {" "}
                        {" " + translate("not_found.title")}
                    </span>

                    <span style={styles.transactionDescription}>
                        {translate("not_found.description")}
                    </span>
                </div>
                <Button
                    style={styles.successButton}
                    onClick={() => {
                        props.history.push("/home");
                    }}
                >
                    {translate("not_found.home")}
                </Button>
            </div>
        </div>
    );
};

export default NotFound;
