/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "antd";
import NumberFormat from "react-number-format";
import { cpf } from "cpf-cnpj-validator";
import { styles } from "./styles";
import { sendDangerNotification } from "../../tools/notifications";
import { colors } from "../../colors";
import { translate } from "../../config/i18n/index";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  input: {
    borderColor: "#fff !important",
    width: "75%",
    marginTop: "5%",
    border: "none",
    color: colors.fontDisabledColor,
  },
  contentContainer: {
    margin: "4%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "60%",
  },
  mobileContentContainer: {
    margin: "4%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
  },
  title: {
    color: colors.fontRegularColor,
    fontSize: "2.8vh",
  },
  inputText: {
    fontFamily: "Poppins, sans-serif",
    textAlign: "right",
    color: colors.fontDisabledColor,
  },
}));

const Identification = (props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [document, setDocument] = useState("");
  const [phone, setPhone] = useState("");
  const [errorCpf, setErrorCpf] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const emailErrorMessage = "O email digitado é inválido";
  const errorMessageCpf = "O CPF digitado é inválido.";
  const phoneErrorMessage = "O telefone digitado é inválido";

  const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);

  return (
    <div
      className={
        windowSize.width < 600
          ? classes.mobileContentContainer
          : classes.contentContainer
      }
    >
      <h1 className={classes.title}>
        {translate("checkout.identification_title")}
      </h1>
      <form autoComplete="off">
        <TextField
          id="standard-basic"
          label={translate("checkout.name")}
          style={{ border: "none", fontFamily: "Poppins, sans-serif" }}
          inputProps={{ min: 0, style: { border: "none" }, maxLength: 60 }}
          className={classes.input}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <TextField
          id="standard-basic"
          label={translate("checkout.email")}
          style={{ border: "none", fontFamily: "Poppins, sans-serif" }}
          inputProps={{
            min: 0,
            style: { border: "none" },
            maxLength: 60,
            type: "email",
          }}
          className={classes.input}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            if (!emailReg.test(e.target.value)) setEmailError(true);
            else setEmailError(false);
          }}
          helperText={emailError ? emailErrorMessage : null}
          error={emailError}
        />

        <NumberFormat
          id="standard-basic"
          label={translate("checkout.document")}
          style={{ border: "none", fontFamily: "Poppins, sans-serif" }}
          inputProps={{ min: 0, style: { border: "none" } }}
          className={classes.input}
          value={document}
          onChange={(e) => {
            setDocument(e.target.value);
            if (cpf.isValid(e.target.value) === false) setErrorCpf(true);
            else setErrorCpf(false);
          }}
          customInput={TextField}
          format="###.###.###-##"
          error={errorCpf}
          helperText={errorMessageCpf && errorCpf ? errorMessageCpf : false}
        />

        <NumberFormat
          id="standard-basic"
          label={translate("checkout.phone")}
          style={{ border: "none", fontFamily: "Poppins, sans-serif" }}
          inputProps={{ min: 0, style: { border: "none" } }}
          className={classes.input}
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            if (
              e.target.value
                .replaceAll(" ", "")
                .replace("(", "")
                .replace(")", "")
                .replace("-", "").length < 10
            )
              setPhoneError(true);
            else setPhoneError(false);
          }}
          customInput={TextField}
          format="(##) #####-####"
          error={phoneError}
          helperText={phoneError ? phoneErrorMessage : false}
        />
      </form>
      <Button
        style={styles.button}
        onClick={() => {
          if (!name || !document || (!phone && !email))
            sendDangerNotification("Os dados precisam ser preenchidos.");
          else if (errorCpf)
            sendDangerNotification("O CPF digitado é inválido.");
          else if (emailError && email.length > 0)
            sendDangerNotification("O email digitado é inválido.");
          else if (phoneError && phone.length > 0)
            sendDangerNotification("O telefone digitado é inválido.");
          else {
            props.storePersonalData({ name, email, document, phone });
            props.setStage();
          }
        }}
      >
        {translate("checkout.confirm_button")}
      </Button>
    </div>
  );
};

export default Identification;
