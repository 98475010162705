import React from "react";
import PropTypes from "prop-types";
import Card from "../Card";

const Container = ({ children, ...props }) => {
  return (
    <Card width={[380, 420, 580, 700]} mx="auto" my={40} {...props}>
      {children}
    </Card>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default Container;
