import styled from "styled-components";
import PropTypes from "prop-types";

const Button: any = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: var(--radius-size);
    margin-top: 10px;

    width: ${(props: any) => props.width || "100%"};
    height: ${(props: any) => props.height || "var(--button-size)"};

    background-color: ${(props: any) => props.backgroundColor};
    color: ${(props) => props.color};

    &.primary {
        background-color: var(--primary-dark-color);
        color: #ffffff;
    }
    &.secondary {
        background-color: #efeaf2;
        color: var(--font-color);
    }

    &.highlight {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
        letter-spacing: 1px;
    }
`;

Button.propTypes = {
    type: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
};

Button.defaultProps = {
    type: "button",
};

export default Button;
