import axios from "axios";
import config from "../config";

export default axios.create({
    baseURL: config.appBackendUri,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-SOUFAN-VERSION-KEY": "602b8c95-9926-4b20-8b1c-e302940a41fb",
    },
});
