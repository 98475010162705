export const colors = {
    buttonPrimaryColor: "#ffb100",
    purpleColor: "#490473",
    fontRegularColor: "#080f38",
    fontDisabledColor: "#c0b8d0",
    lightPurple: "#A926FC",
    darkPurple: "#4A0F8E",
    purple_back: "#B23CFC",

    gray: "#f2edf8",
    darkGray: "#857C9A",
    grayPurple: "#C0B8D0",

    pink: "#E90C80",
    pink_dark: "#C300FF",

    blue_aqua: "#26BAC6",
    blue_dark: "#080F38",
    blue: "#09CAF0",
    blue_light: "#22CFF1",

    lightGreen: "#59df3c",
};
