export default {
  defaultSeller: {
    logo: process.env.REACT_APP_PROVIDER_LOGO,
    color: process.env.REACT_APP_PROVIDER_COLOR
      ? process.env.REACT_APP_PROVIDER_COLOR
      : "",
    name: process.env.REACT_APP_PROVIDER_NAME,
    email: process.env.REACT_APP_PROVIDER_EMAIL,
    website: process.env.REACT_APP_PROVIDER_WEBSITE,
    sellerId: process.env.REACT_APP_PROVIDER_SELLER_ID,
  },
  providerId: process.env.REACT_APP_PROVIDER_ID,
  appBackendUri: process.env.REACT_APP_BACKEND_URI,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || "",
  transactionTypes: {
    purchase: "Compra",
    donation: "Doação",
    offer: "Oferta",
    tithe: "Dízimo",
  },
};
