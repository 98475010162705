import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FaSpinner, FaChevronRight } from "react-icons/fa";
import { Formik, ErrorMessage, Field } from "formik";
import { cpf } from "cpf-cnpj-validator";
import { parseISO, format } from "date-fns";
import { ptBR } from "date-fns/locale";

import config from "../../config";
import api from "../../services/api";
import extractError from "../../lib/extract-error-from-axios";
import currency from "../../lib/currency";
import Body from "../../components/Body";
import Container from "../../components/Container";
import VerticalSpace from "../../components/VerticalSpace";
import Logo from "../../components/Logo";
import Form from "../../components/Form";
import FormSection from "../../components/FormSection";
import CurrencyInput from "../../components/CurrencyInput";
import InputError from "../../components/InputError";
import SubmitButton from "../../components/SubmitButton";
import Button from "../../components/Button";
import { Section } from "./styles";
import { lightToDark } from "../../lib/color";
import { sendRedeemSuccessNotification } from "./notifications";
import {
  sendGenericFailureNotification,
  sendIncorrectFormFieldsFailureNotification,
} from "../../tools/notifications";

import validator from "./validator";
import yup from "../../tools/yup";

const resetableState = {};

export default class Main extends Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        voucher: PropTypes.object,
        businessInfo: PropTypes.object,
      }),
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func,
      push: PropTypes.func,
    }).isRequired,
  };

  state = {
    ...resetableState,
    businessInfo: {},
    voucher: {},
    loaded: false,
  };

  async componentDidMount() {
    const { location, history } = this.props;
    const { state } = location;
    if (state) {
      const { businessInfo, voucher } = state;
      this.setState({ businessInfo, voucher, loaded: true });
    } else {
      history.replace({ pathname: "/redeem" });
    }
  }

  handleSubmit = async (values) => {
    const { voucher, businessInfo } = this.state;
    const { history } = this.props;

    const amount = currency.toCents(values.amount);
    const amountString = `R$${currency.fromCents(amount)}`;

    const payloadVoucher = {
      amount,
      code: voucher.code,
      sellerId: businessInfo.sellerId,
      establishmentId: businessInfo.id,
    };

    try {
      await api.post("/v2/redeem/vouchers/redeem", payloadVoucher);

      sendRedeemSuccessNotification(amountString);

      history.push({
        pathname: "/redeem/success",
        state: { businessInfo, amount: values.amount, name: voucher.name },
      });
    } catch (e) {
      const { error, code } = extractError(e);
      if (code === "InvalidInput") {
        sendIncorrectFormFieldsFailureNotification(
          error.extensions.invalidInput
        );
      } else {
        sendGenericFailureNotification(
          config.defaultSeller.email,
          error.message
        );
      }
    }
  };

  render() {
    const { businessInfo, voucher } = this.state;
    const balancaceValue = voucher.redeemedValue
      ? voucher.redeemValue - voucher.redeemedValue
      : voucher.redeemValue;

    const dynamicValidator = validator.shape({
      amount: yup
        .string()
        .trim()
        .required("Esse campo é obrigatório")
        .maxCurrency(balancaceValue, "O valor máximo foi excedido"),
    });

    return (
      <Body primaryColor={businessInfo.color}>
        <Container>
          <Logo src={businessInfo.logo ? businessInfo.logo.location : ""} />
          <VerticalSpace />
          <h1>Resgate de voucher</h1>
          <Section>
            <h3>Valor disponível para resgate</h3>
            <p className="currencyHighlight">
              <span>R$</span>
              {currency.fromCents(balancaceValue)}
            </p>
          </Section>
          <Section>
            <h3>Informações do voucher</h3>
            <ul>
              <li>
                <strong>Nome: </strong>
                {voucher.name}
              </li>
              <li>
                <strong>CPF: </strong>
                {cpf.format(voucher.document)}
              </li>
              <li>
                <strong>Email: </strong>
                {voucher.email}
              </li>
            </ul>
          </Section>
          {voucher.redeemedAt ? (
            <Section>
              <h3>Detalhes de resgate</h3>
              <ul>
                <li>
                  <strong>Valor original: </strong>
                  R${currency.fromCents(voucher.redeemValue)}
                </li>
                <li>
                  <strong>Valor já resgatado: </strong>
                  R${currency.fromCents(voucher.redeemedValue)}
                </li>
                <li>
                  <strong>Histórico de resgate: </strong>
                  <ul>
                    {voucher.history
                      .filter((transaction) => transaction.type === "redeem")
                      .map((transaction) => (
                        <li key={transaction.id}>
                          <FaChevronRight size={14} /> R$
                          {currency.fromCents(transaction.value)} em{" "}
                          {format(
                            parseISO(transaction.createdAt),
                            "dd/MM/yyyy 'às' HH'h'mm",
                            { locale: ptBR }
                          )}
                        </li>
                      ))}
                  </ul>
                </li>
              </ul>
            </Section>
          ) : (
            <></>
          )}
          <Formik
            initialValues={{
              amount: "",
            }}
            validationSchema={dynamicValidator}
            onSubmit={this.handleSubmit}
          >
            {(props) => (
              <Form>
                <FormSection>
                  <h3>Resgate parcial</h3>
                  <Field
                    name="amount"
                    placeholder="Digite o valor que deseja resgatar"
                    component={CurrencyInput}
                  />
                  <ErrorMessage name="amount" component={InputError} />
                </FormSection>
                <VerticalSpace />
                <Button
                  as={Link}
                  to={{
                    pathname: "/redeem",
                    state: { businessInfo },
                  }}
                  className="secondary highlight"
                >
                  Cancelar
                </Button>
                <VerticalSpace size={20} />
                <Button
                  as={SubmitButton}
                  disabled={props.isSubmitting}
                  submitting={props.isSubmitting}
                  style={{
                    backgroundColor: lightToDark(businessInfo.brandColor),
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  {props.isSubmitting ? (
                    <FaSpinner color="#ffffff" size={14} />
                  ) : (
                    <>Resgatar voucher</>
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Container>
      </Body>
    );
  }
}
