import styled from "styled-components";

import device from "../../../styles/device";
import Card from "../../../components/Card";
import { colors } from "../../../colors";

export const ScrollMenuItemContainer = styled(Card)`
    h3 {
        margin: 10px auto;
    }
`;

export const styles = {
    title: {
        fontFamily: "Poppins, sans-serif",
        color: colors.fontRegularColor,
        fontSize: "180%",
        fontWeight: 1000,
        lineHeight: "100%",
        width: "40%",
        marginBottom: "3%",
    },
    grid: {
        margin: 10,
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        width: '300px',
        padding: '10px',
        height: '70px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#eee',
        marginBottom: "20px"
    } as unknown as React.CSSProperties,
    inputLabel: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.8vh',
        fontWeight: 'bold',
        color: '#080F38',
        marginTop: '3px',
        marginLeft: '15px'
    } as unknown as React.CSSProperties,
    input: { 
        border: 'none', 
        width: '100%',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px'
    }
};

export const LogoContainer = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: calc(100% * 9 / 12);

    @media ${device.small} {
        padding-bottom: calc(100% * 9 / 14);
    }

    @media ${device.medium} {
        padding-bottom: calc(100% * 9 / 16);
    }
    @media ${device.large} {
        padding-bottom: calc(100% * 9 / 22);
    }

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 20px;
    }
`;

export const TitleLogoContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;

    button {
        margin-top: 10px;

        svg {
            margin-right: 10px;
        }
    }

    @media ${device.medium} {
        flex-direction: row;

        button:first-child {
            margin-left: 0;
        }

        button {
            margin-left: 10px;
            width: 48%;
        }
    }
`;
