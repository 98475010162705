import React from "react";

import config from "../../config";
import Body from "../../components/Body";
import HeaderCard from "./HeaderCard";
import { FeaturedSellers } from "./FeaturedSellers";
import { styles } from "./styles";

export default ({history}) => {
    return (
        <Body primaryColor={config.defaultSeller.color}>
            <div>
                <HeaderCard />
                <img
                    src={require("../../assets/banner-fanapp2.png")}
                    alt="banner"
                    style={styles.img}
                />
                <a href={'https://apps.apple.com/ph/app/fanapp-divers%C3%A3o-antecipada/id1560743258'}>
                <img
                    src={require("../../assets/app-store-badge.png")}
                    alt="badge"
                    style={styles.badge}
                />
                </a>
                <a href={'https://play.google.com/store/apps/details?id=app.soufan.customer'}>
                <img
                    src={require("../../assets/google-play-badge.png")}
                    alt="google-play"
                    style={styles.badgeApp}
                />
                </a>
            </div>
            <FeaturedSellers history={history} />
        </Body>
    );
};
