import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    input {
        margin-top: 15px;
    }
`;
