import styled from "styled-components";
import PropTypes from "prop-types";

import Card from "../Card";
import parseLengthProp from "../../lib/parse-length-prop";

const QrCodeReaderStyle = styled(Card).attrs(() => ({
  padding: 0,
}))`
  --frame-size: ${(props) => parseLengthProp(props.size)};
  --frame-min-size: ${(props) => parseLengthProp(props.minSize)};
  --frame-max-size: ${(props) => parseLengthProp(props.maxSize)};
  --corner-edge-space: ${(props) => parseLengthProp(props.cornerEdgeSpace)};
  --corner-edge-border: ${(props) => parseLengthProp(props.cornerEdgeBorder)};
  --corner-edge-color: ${(props) => props.cornerEdgeColor};

  position: relative;
  width: clamp(var(--frame-min-size), var(--frame-size), var(--frame-max-size));
  padding-bottom: clamp(
    var(--frame-min-size),
    var(--frame-size),
    var(--frame-max-size)
  );
  overflow: hidden;

  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${(props) => props.backgroundColor};

    section {
      width: 100%;
      height: 100%;

      img,
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 1;
        transform: scale(${(props) => (props.zoom ? 2 : 1)});
      }
    }

    // Action buttons (zoom in/out, turn on/off)
    header {
      position: absolute;
      bottom: calc(var(--corner-edge-space) + var(--corner-edge-border) + 5px);
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 40%;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border: 0;
        border-radius: 50%;
        background: #ffffff;
        box-shadow: var(--box-shadow);
      }
    }

    // Framing reference
    :before,
    :after,
    > :last-child:before,
    > :last-child:after {
      content: "";
      position: absolute;
      z-index: 2;
      width: 60px;
      height: 50px;
      border-color: var(--corner-edge-color);
      border-style: solid;
    }

    :before {
      top: var(--corner-edge-space);
      left: var(--corner-edge-space);
      border-width: var(--corner-edge-border) 0 0 var(--corner-edge-border);
      border-radius: var(--radius-size) 0 0 0;
    }

    :after {
      top: var(--corner-edge-space);
      right: var(--corner-edge-space);
      border-width: var(--corner-edge-border) var(--corner-edge-border) 0 0;
      border-radius: 0 var(--radius-size) 0 0;
    }

    > :last-child:before {
      bottom: var(--corner-edge-space);
      right: var(--corner-edge-space);
      border-width: 0 var(--corner-edge-border) var(--corner-edge-border) 0;
      border-radius: 0 0 var(--radius-size) 0;
    }

    > :last-child:after {
      bottom: var(--corner-edge-space);
      left: var(--corner-edge-space);
      border-width: 0 0 var(--corner-edge-border) var(--corner-edge-border);
      border-radius: 0 0 0 var(--radius-size);
    }
  }
`;

QrCodeReaderStyle.propTypes = {
  minSize: PropTypes.string,
  maxSize: PropTypes.string,
  cornerEdgeSpace: PropTypes.string,
  cornerEdgeBorder: PropTypes.string,
  cornerEdgeColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  zoom: PropTypes.bool,
};

QrCodeReaderStyle.defaultProps = {
  size: "100%",
  minSize: "200px",
  maxSize: "400px",
  cornerEdgeSpace: "30px",
  cornerEdgeBorder: "5px",
  cornerEdgeColor: "var(--primary-dark-color)",
  backgroundColor: "#ffffff",
  zoom: false,
};

export default QrCodeReaderStyle;
