export default (value) => {
  if (!value) {
    return true;
  }

  if (!value.match(/^(0[1-9]|1[0-2])\/\d{2}$/)) {
    return false;
  }

  const today = new Date();
  const expirationDay = new Date();
  const prefixYear = today.getFullYear().toString().slice(0, 2);

  const [rawMonth, rawYear] = value.split("/");
  // JS considers month date from 0 to 11, then is necessary subtract one
  // But credit card expiration considers next month, then is necessary plus one
  // These behaviors cancel each other
  const month = rawMonth;
  const year = rawYear.length === 2 ? `${prefixYear}${rawYear}` : rawYear;

  expirationDay.setFullYear(year, month, 1);

  return today < expirationDay;
};
